import * as PropTypes from "prop-types"
import { Box, Grid, Tooltip, FormGroup, FormControlLabel, Checkbox, fabClasses } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import { Fragment, useState } from "react"
import { DataGrid } from "@mui/x-data-grid"
import { Visualization3D } from "../Visualization3D"
import { ligandColor, 
  proteinLigandPairsTypes, 
  readFileAsync,
  InferenceResultsType } from "../../utils"
import { ResultItem } from "../ResultItem"
import { ResultDescriptionItem } from "../ResultDescriptionItem"
import { Modal } from "../Modal"
import Title from "../Title"
import { PoseColorExplanationTable } from "../PoseColorExplanationTable"

PaperResultsTables.propTypes = {
  inferenceResults: InferenceResultsType.isRequired,
  proteinLigandPairs: proteinLigandPairsTypes,
  pdb: PropTypes.instanceOf(File),
  reference: PropTypes.instanceOf(File)
}

export function PaperResultsTables({ inferenceResults, proteinLigandPairs, pdb, reference }) {
  const [selectedPosesForVisualization, setSelectedPosesForVisualization] = useState([])
  const [showReferenceLigand, setShowReferenceLigand] = useState(true)
  const [modal, setModal] = useState(null) // object with title and description

  const findIfAnyDownloadLinkExists = () => {
    return proteinLigandPairs.some(e => !!e.download_ligand_url)
  }

  const findLigandIndex = (ligandId) => {
    if (proteinLigandPairs.length === 0) return -1
    const ligandIndex = proteinLigandPairs
      .map(e => e.docked_ligand.name.replace(" ", "_"))
      .findIndex(ligandName => ligandName.endsWith(`${ligandId}.sdf`))
    return ligandIndex
  }

  const findDownloadLink = (ligandId) => {
    // AI Generated Function
    if (proteinLigandPairs.length === 0) return null
    const ligandIndex = findLigandIndex(ligandId)
    if (ligandIndex > -1) {
      return proteinLigandPairs[ligandIndex].download_ligand_url
    }
  }

  const downloadPose = async (e, poseId, ligandId) => {
    // AI generated function
    e.preventDefault()
    const ligandIndex = findLigandIndex(ligandId)
    const ligandFile = proteinLigandPairs[ligandIndex].docked_ligand
    
    const content = await readFileAsync(ligandFile)
    const poses = content.split("$$$$\n")
    const pose = poses[poseId]
    const blob = new Blob([pose], { type: "text/plain" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${ligandId}_pose_${poseId}.sdf`
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const renderLigandCell = (params) => {
    const downloadLink = findDownloadLink(params.value)
    return downloadLink ? <a href={downloadLink} target="_blank" rel="noopener noreferrer">{params.value}</a> : params.value
  }

  const renderLigandCellPoses = (params) => {
    const poseId = Number(params.row.pose_id)
    const ligandId = params.value
    const donwloadLinkExists = !!findDownloadLink(ligandId)
    return donwloadLinkExists ? <a href="#" onClick={(e) => {downloadPose(e, poseId, ligandId)}}>{ligandId}</a> : ligandId
  }

  const affinityDataGridColumns = [
    { field: "pdb_id", headerName: "pdb id", flex: 1, headerClassName: "results-header-class" },
    { field: "ligand_id", headerName: "ligand id", flex: 1, headerClassName: "results-header-class", renderCell: renderLigandCell },
    { field: "ligand_affinity", headerName: "ligand affinity", flex: 1, headerClassName: "results-header-class" },
  ]

  const affinityDataGridRows = []
  inferenceResults["inference_ligand_summary"].forEach((row, i) => {
    affinityDataGridRows.push({
      id: i,
      pdb_id: row["pdb_id"],
      ligand_id: row["ligand_id"],
      ligand_affinity: row["ligand_affinity"],
    })
  })

  function renderCellColor (params) {
    return <Box sx={{
      backgroundColor: params.row.row_color,
      width: 10,
      height: 10
    }} />
  }
            
  function handleVisualizationHelpClick() {
    setModal({title: "Visualization Help", 
        description: <>
          <p>Select poses from the table to visualize. Hold down primary mouse button to rotate the structure. Hold the secondary mouse button to pan. Scroll to zoom. Hold shift and scroll to slice.</p>
          <PoseColorExplanationTable  />
        </>})
  }

  const poseDataGridColumns = [
    { field: "pdb_id", headerName: "pdb id", flex: 1, headerClassName: "results-header-class" },
    {
      field: "ligand_id", headerName: "ligand id", flex: 1, headerClassName: "results-header-class", renderCell: renderLigandCellPoses
    },
    { field: "pose_id", headerName: "pose id", flex: 0.45, headerClassName: "results-header-class", type: "number", align: "right" },
    {
      field: "pose_confidence",
      headerName: "pose confidence",
      flex: 0.75,
      description: "Confidence of true pose",
      headerClassName: "results-header-class",
      type: "number",
      align: "right"
    },
    {
      field: "pose_affinity",
      headerName: "pose affinity",
      flex: 0.75,
      description: "Affinity prediction of pose",
      headerClassName: "results-header-class",
      type: "number",
      align: "right"
    },
    { field: "row_color", headerName: "", flex: 0.2, renderCell: renderCellColor, sortable: false }
  ]

  const poseDataGridRows = []
  inferenceResults["inference_results"].forEach((row, i) => {
    let rowColor = "#FFFFFF00"
    if (proteinLigandPairs.length > 0) {
      const ligandIndex = proteinLigandPairs
        .map(e => e.docked_ligand.name.replace(" ", "_"))
        .findIndex(ligandName => ligandName.includes(`${row["ligand_id"]}.sdf`) || ligandName.includes(`${row["ligand_id"]}.mol2`) || ligandName.includes(`${row["ligand_id"]}.pdb`))
      if (ligandIndex > -1) {
          rowColor =  ligandColor({ligandIndex: ligandIndex, 
            poseNumber: parseInt(row["pose_id"]),
            poseConfidence: row["pose_confidence"],
            poseAffinity: row["pose_affinity"]
          })
      }
    }
    poseDataGridRows.push({
      id: i,
      pdb_id: row["pdb_id"],
      ligand_id: row["ligand_id"],
      pose_id: row["pose_id"],
      pose_confidence: row["pose_confidence"],
      pose_affinity: row["pose_affinity"],
      row_color: rowColor
    })
  })

  function onRowSelection (selectedRows) {
    if (selectedRows.length === 0 && !!pdb) {
      setSelectedPosesForVisualization([{ pdb_id: pdb.name }])
    } else {
      const selectedDetails = selectedRows.map(i => {
        return {
          ligand: poseDataGridRows[i].ligand_id,
          pose_id: poseDataGridRows[i].pose_id,
          pdb_id: poseDataGridRows[i].pdb_id
        }
      })
      setSelectedPosesForVisualization(selectedDetails)
    }
  }

  const poseDataGridTable = <DataGrid columns={poseDataGridColumns}
                                      rows={poseDataGridRows}
                                      initialState={{
                                        pagination: { paginationModel: { pageSize: 100 } },
                                      }}
                                      columnVisibilityModel={{
                                        pdb_id: false
                                      }}
                                      pageSizeOptions={[100]}
                                      checkboxSelection
                                      onRowSelectionModelChange={onRowSelection}
                                      disableRowSelectionOnClick />

  return (<Fragment>
    <ResultItem title={"Ligand affinity predictions"}
                dataDownload={inferenceResults["inference_ligand_summary"]}
                downloadName={"ligand_affinity_predictions.csv"}>
      <ResultDescriptionItem title={"ligand_affinity"}>
        Overall ligand affinity, expressed in pKi units, is obtained from the aggregation of the predicted pose affinities, weighted according to the Boltzmann distribution of the pose confidence score.
      </ResultDescriptionItem>
      {findIfAnyDownloadLinkExists() && <ResultDescriptionItem>
        The poses for each ligand can be downloaded by clicking the ligand id links in the table.
      </ResultDescriptionItem>}
      <Title md sx={{ marginTop: "1.5%", marginBottom: "1.5%", textAlign: "left" }}>Table</Title>
      <div style={{ height: 371, width: "50%" }}>
        <DataGrid
          columns={affinityDataGridColumns}
          rows={affinityDataGridRows}
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          pageSizeOptions={[100]} />
      </div>
    </ResultItem>

    <ResultItem title={"Pose predictions"}
                dataDownload={inferenceResults["inference_results"]}
                downloadName={"pose_predictions.csv"}>
      {findIfAnyDownloadLinkExists() && <ResultDescriptionItem>
        Individual ligand poses can be downloaded by clicking the ligand id links in the table.
      </ResultDescriptionItem>}
      <ResultDescriptionItem title={"pose_confidence"}>
        Pose confidence, ranging from low (0) to high (1), indicates the model's confidence that the pose could be the true, protein-ligand co-crystal structure. Note that this is solely based on the model's prediction and not a direct comparison with an existing co-crystal structure. 
      </ResultDescriptionItem>
      <ResultDescriptionItem title={"pose_affinity"}> 
        Predicted affinity of the protein-pose pair, expressed in pKi units. 
      </ResultDescriptionItem>
      <ResultDescriptionItem>Click on the help button <HelpOutlineIcon color="primary" fontSize="small" onClick={handleVisualizationHelpClick} /> for more information about the visualization.</ResultDescriptionItem>
      {!!reference && <ResultDescriptionItem>
        <FormGroup>
          <FormControlLabel label="Show reference ligand" control={
            <Checkbox
              size="small"
              checked={showReferenceLigand}
              onChange={(e) => setShowReferenceLigand(e.target.checked)} />
          } />
        </FormGroup>
      </ResultDescriptionItem>}
      <div className="poseVisualizationDiv poseVisualizationDivActive">
        <Grid container maxWidth={"100vw"}>
          <Grid item xs={6}>
            <Title md sx={{ marginTop: "1.5%", marginBottom: "1.5%", textAlign: "left" }}>Table</Title>
          </Grid>
          <Grid item xs={6}>
            <Title md sx={{ marginTop: "1.5%", marginBottom: "1.5%", textAlign: "center" }}>
              Visualization
              <Tooltip
                title="Select poses below to visualize. Hold down primary mouse button to rotate the structure. Hold the secondary mouse button to pan. Scroll to zoom. Hold shift and scroll to slice. Click for more info.">
                <HelpOutlineIcon color="primary" fontSize="small" onClick={handleVisualizationHelpClick} />
              </Tooltip>
            </Title>
          </Grid>
          <Grid item id="poseDataGridItem" xs={6} sx={{ height: "70vh" }}>
            {poseDataGridTable}
          </Grid>
          <Grid item sx={{ width: "50%" }} xs={6} id="visualizationGridItem">

            <Visualization3D proteinLigandPairs={proteinLigandPairs}
                             selectedPosesForVisualization={selectedPosesForVisualization} 
                             reference={showReferenceLigand ? reference : null} 
                             inferenceResults={inferenceResults.inference_results} />
          </Grid>
        </Grid>
      </div>
    </ResultItem>
    {modal && <Modal
    onClose={() => setModal(null)}
    width={"80%"}
    title={modal.title}
    open={true}>{modal.description}</Modal>}
  </Fragment>)
}

export default PaperResultsTables
