import { Grid, Typography } from "@mui/material"
import PropTypes from "prop-types"

const DEFAULT_INPUT_SX = {
    color: "black",
    fontSize: "1.125rem",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "1.4"
}

ResultDescriptionItem.propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    sx: PropTypes.object
}

ResultDescriptionItem.defaultTypes = {
    sx: DEFAULT_INPUT_SX
}

export function ResultDescriptionItem({title, children, sx}) {

    const active_sx = {...DEFAULT_INPUT_SX, ...sx}

    return (<Grid container direction="column"
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start" 
                sx={{width: "75%", marginTop: "1.5%"}}  >
        <Grid item md={6} textAlign={"start"}>
            <Typography variant="p" sx={{...active_sx, ...{fontWeight: "500"}}} >{title}</Typography>
        </Grid>
        <Grid item md={6} textAlign={"start"}>
            <Typography variant="p" sx={active_sx} >{children}</Typography>
        </Grid>
    </Grid>)
}

export default ResultDescriptionItem;