import { CssBaseline, ThemeProvider } from "@mui/material"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import PaperToolPage from "./pages/PaperToolPage"
import AboutPage from "./pages/AboutPage"
import HydrascreenSubmitJobPage from "./pages/HydrascreenSubmitJobPage"
import { TermsAndConditions } from "./pages/TermsAndConditions"
import { PrivacyPolicy } from "./pages/PrivacyPolicy"
import { AuthenticationContext } from "./context"
import { useEffect, useState } from "react"
import { getUserToken, isUserTokenValid, setUserToken } from "./utils"
import { Auth } from "./api"
import { TokenPage } from "./pages/TokenPage"
import { ResultPage } from "./pages/ResultPage"
import { LoginPage } from "./pages/LoginPage"
import theme from "./theme"

const App = () => {
  const [loggedInUser, setLoggedInUser] = useState()

  const isUserLoggedIn = () => {
    return loggedInUser != null
  }

  useEffect(() => {
    const token = getUserToken()
    if (isUserTokenValid(token)) {
      setLoggedInUser(new Auth(token))
    }
  }, [])

  function logOutUser() {
    setLoggedInUser(null)
    setUserToken(null)
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthenticationContext.Provider value={{
        user: loggedInUser,
        login: setLoggedInUser,
        isUserLoggedIn: isUserLoggedIn,
        logout: logOutUser
      }}>
        <CssBaseline />
        <BrowserRouter basename="/">
          <Routes>
            <Route exact path="/submit_job" element={<HydrascreenSubmitJobPage />} />
            <Route exact path="/results/:runId" element={<ResultPage />} />
            <Route exact path="/paper" element={<PaperToolPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/terms_and_conditions" element={<TermsAndConditions />} />
            <Route exact path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route exact path="/token/:userToken" element={<TokenPage />} />
            <Route exact path="/login/:runId" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/submit_job" replace />} />
          </Routes>
        </BrowserRouter>
      </AuthenticationContext.Provider>
    </ThemeProvider>
  )
}

export default App