import { Container, Typography, Stack } from "@mui/material"
import { api } from "../api"
import { Title } from "./Title.jsx"
import { StyledButton } from "./StyledButton"
import { useNavigate, useLocation } from "react-router-dom"

const Paragraph = ({ children }) => (
  <Typography sx={{ color: "black", fontSize: "1.5rem" }}>{children}</Typography>
)

const LandingSlogan = () => {
  const navigate = useNavigate();
  async function downloadRedockedDataset() {
    const url = await api.getRedockedDatasetDownloadUrl()
    window.open(url)
  }
  const urlPathname = useLocation().pathname

  const isPaperPage = urlPathname === "/paper"


  return (<Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 14 }}>
    <Stack direction="column" spacing={3}>
      <Title sx={{textAlign: "left"}} xl>HydraScreen</Title>
      <Paragraph>
        HydraScreen is an AI tool that predicts protein-ligand affinity and pose score for a set of docked poses. The user
        submits their docked poses as a protein PDB file and ligand SDF, mol2, or PDB files of the docked poses. The model then ranks poses
        by confidence level and ligands by predicted affinity (e.g. pKi).
      </Paragraph>
      {isPaperPage && <Paragraph>
        Compared to traditional methods like docking, HydraScreen demonstrates increased accuracy through public benchmarks and prospective validation studies. More detailed information about the model and its performance is available in our <a href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2" target="_blank">publication</a>. You can access RD-2020, the re-docked dataset used to train and evaluate HydraScreen, <a href="#" onClick={() => downloadRedockedDataset()}>here</a>. HydraScreen is also available through our open-source <a href="https://github.com/Ro5-ai/hydrascreen" target="_blank">Python API.</a>
      </Paragraph>}
      {isPaperPage ?
            <StyledButton secondaryColor={true} 
                onClick={() => {navigate("/")}} 
                size="small" 
                sx={{width: "200px"}}>Go back to the main app</StyledButton> 
            : 
            <StyledButton secondaryColor={true} 
                onClick={() => {navigate("/about")}} 
                size="small" 
                sx={{width: "160px"}}>Find out more</StyledButton>
      }
    </Stack>
  </Container>)
}

export default LandingSlogan
