import { Typography } from "@mui/material"
import PropTypes from "prop-types"

const DEFAULT_TITLE_STYLE = {
  fontWeight: "500",
  color: "primary.main",
  textAlign: "center"
}

Title.propTypes = {
  sx: PropTypes.object,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  id: PropTypes.string,
}

Title.defaultProps = {
  sx: DEFAULT_TITLE_STYLE,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  id: ""
}


function getTitleVariant(sm, md, lg, xl) {
  if (sm) return "h6"
  if (md) return "h5"
  if (lg) return "h4"
  if (xl) return "h3"

  return "h4"
}


export function Title({ children, sx, sm, md, lg, xl, id}) {
  const activeSx = { ...DEFAULT_TITLE_STYLE, ...sx }
  let variant = getTitleVariant(sm, md, lg, xl)

  return (
    <Typography variant={variant} sx={activeSx} id={id}>
      {children}
    </Typography>
  )
}

export default Title;
