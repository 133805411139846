import * as PropTypes from "prop-types"
import { Box, Grid, Tooltip, FormGroup, FormControlLabel, Checkbox } from "@mui/material"
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"
import HelpIcon from "@mui/icons-material/Help"
import { Fragment, useState, useMemo, useEffect } from "react"
import { Visualization3D } from "../Visualization3D"
import { ligandColor, 
  proteinLigandPairsTypes, 
  readFileAsync, 
  InferenceResultsType  } from "../../utils"
import { ResultItem } from "../ResultItem"
import { ResultDescriptionItem } from "../ResultDescriptionItem"
import { Modal } from "../Modal"
import Title from "../Title"
import { PoseColorExplanationTable } from "../PoseColorExplanationTable"
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import { CsvDescriptionAndDownload } from "../CsvDescriptionAndDownload"

AppResultsTables.propTypes = {
  inferenceResults: InferenceResultsType.isRequired,
  proteinLigandPairs: proteinLigandPairsTypes,
  pdb: PropTypes.instanceOf(File),
  reference: PropTypes.instanceOf(File)
}

export function AppResultsTables({ inferenceResults, proteinLigandPairs, pdb, reference }) {
  const [selectedPosesForVisualization, setSelectedPosesForVisualization] = useState([])
  const [showReferenceLigand, setShowReferenceLigand] = useState(true)
  const [modal, setModal] = useState(null) // object with title and description
  const [rowSelection, setRowSelection] = useState({})

  const ligandDownloadLinkExists = proteinLigandPairs.some(e => !!e.download_ligand_url);

  const findLigandIndex = (ligandId) => {
    if (proteinLigandPairs.length === 0) return -1
    const ligandIndex = proteinLigandPairs
      .map(e => e.docked_ligand.name.replace(" ", "_"))
      .findIndex(ligandName => ligandName.endsWith(`${ligandId}.sdf`))
    return ligandIndex
  }

  const findDownloadLink = (ligandId) => {
    // AI Generated Function
    if (proteinLigandPairs.length === 0) return null
    const ligandIndex = findLigandIndex(ligandId)
    if (ligandIndex > -1) {
      return proteinLigandPairs[ligandIndex].download_ligand_url
    }
  }

  const downloadPose = async (e, poseId, ligandId) => {
    // AI generated function
    e.preventDefault()
    const ligandIndex = findLigandIndex(ligandId)
    const ligandFile = proteinLigandPairs[ligandIndex].docked_ligand
    
    const content = await readFileAsync(ligandFile)
    const poses = content.split("$$$$\n")
    const pose = poses[poseId]
    const blob = new Blob([pose], { type: "text/plain" })
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url
    a.download = `${ligandId}_pose_${poseId}.sdf`
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  const renderLigandCell = (params) => {
    let value = params.value
    if (!value) value = params.renderedCellValue
    const downloadLink = findDownloadLink(value)
    return downloadLink ? <a href={downloadLink} target="_blank" rel="noopener noreferrer">{value}</a> : value
  }

  const renderPoseIdCell = (params) => {
    if (params.renderedCellValue === '') return ''
    const poseId = Number(params.renderedCellValue)

    if (!params.row.getParentRow()) {
      return poseId
    }
    
    const ligandId = params.row.getParentRow().getValue('ligand_id')
    const donwloadLinkExists = !!findDownloadLink(ligandId)
    return donwloadLinkExists ? <a href="#" onClick={(e) => {downloadPose(e, poseId, ligandId)}}>{poseId}</a> : poseId
  }

  function renderCellColor (params) {
    return <Box sx={{
      backgroundColor: params.renderedCellValue,
      width: 10,
      height: 10
    }}/>
  }
            
  function showVisualizationHelpModal() {
    setModal({title: "Visualization Help", 
        description: <>
          <p>Select poses from the table to visualize. Hold down primary mouse button to rotate the structure. Hold the secondary mouse button to pan. Scroll to zoom. Hold shift and scroll to slice.</p>
          <PoseColorExplanationTable  />
        </>})
  }

  function showDownloadModal() {
    setModal({title: 'Download HydraScreen Predictions',
            description: <CsvDescriptionAndDownload includeDownload={true} 
                                                    inferenceResults={inferenceResults}/>})
  }

  const columns = useMemo(() => [
    {header: "pdb id", accessorKey: "pdb_id", enableGrouping: false},
    {header: "ligand id", 
      accessorKey: "ligand_id", 
      enableGrouping: false, 
      Cell: renderLigandCell},
    {header: "pose id", 
      accessorKey: "pose_id", 
      enableGrouping: false, 
      size: 60, 
      enableSorting: true,
      Cell: renderPoseIdCell,
      sortingFn: (a, b, _) => {
        return Number(a.getValue('pose_id')) - Number(b.getValue('pose_id'))
      }
    },
    {header: "ligand / pose affinity", accessorKey: "pose_affinity", size: 80, enableGrouping: false},
    {header: "pose confidence", accessorKey: "pose_confidence", size:80, enableGrouping: false},
    {header: "", accessorKey: "color", size: 30, 
    enableGrouping: false, enableSorting: false, enableColumnActions: false, 
    enableColumnFilters: false, Cell: renderCellColor}
  ], [renderLigandCell, renderPoseIdCell, renderCellColor])

  let gridTableData = useMemo(() => inferenceResults["inference_ligand_summary"].map((row, i) => {
    return {
      pdb_id: row["pdb_id"],
      ligand_id: row["ligand_id"],
      pose_id: "",
      pose_affinity: row["ligand_affinity"],
      pose_confidence: "",
      color: "#FFFFFF00",
      rowIndex: i,
      subRows: inferenceResults["inference_results"].filter(subrow => 
        subrow["ligand_id"] === row["ligand_id"]).map((subrow, j) => {
          return {
            pdb_id: subrow["pdb_id"],
            ligand_id: "",
            pose_id: subrow["pose_id"],
            pose_affinity: subrow["pose_affinity"],
            pose_confidence: subrow["pose_confidence"],
            color: ligandColor({ligandIndex: findLigandIndex(row["ligand_id"]), 
              poseNumber: parseInt(subrow["pose_id"]),
              poseConfidence: subrow["pose_confidence"],
              poseAffinity: subrow["pose_affinity"]
            }),
            subrowIndex: j
          }
        })
    }
  }), [inferenceResults])

  const poseDataGridTable = useMaterialReactTable({
    columns: columns, 
    data: gridTableData, 
    displayColumnDefOptions: {
      'mrt-row-expand': {
        enableResizing: true,
        size: 30
      },
    },
    filterFromLeafRows: true,
    enableExpanding: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableDensityToggle: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableRowSelection: true,
    layoutMode: "grid",
    getSubRows: (row) => row.subRows,
    initialState: {
      density: 'compact',
      expanded: false, 
      pagination: { pageIndex: 0, pageSize: 100 },
      sorting: [{ id: 'ligand_id', desc: false }, {id: 'pose_id', desc: false}], 
      hiddenColumns: ['pdb_id'],
      columnVisibility: {pdb_id: false}
    },
    state: {rowSelection},
    muiToolbarAlertBannerChipProps: { color: 'primary' },
    muiTablePaperProps: { sx: { maxHeight: '70vh', overflow: 'overlay' } },
    muiTableContainerProps: {sx: { maxHeight: 'unset'}},
    muiTableHeadCellProps: {sx: {'& .Mui-TableHeadCell-Content-Wrapper': {textWrap: 'wrap'}}},
    onRowSelectionChange: setRowSelection,
  })

  useEffect(() => {
    let selectedRows = []
    for (let rowNumber in rowSelection) {
      if (rowNumber.includes(".")) {
        const [rowIndex, subRowIndex] = rowNumber.split(".")
        let ligandLevel = gridTableData.filter(row => row.rowIndex === Number(rowIndex))[0]
        let poseLevel = ligandLevel.subRows.filter(subrow => subrow.subrowIndex === Number(subRowIndex))[0]
        selectedRows.push({
          ligand: ligandLevel.ligand_id,
          pose_id: poseLevel.pose_id,
          pdb_id: poseLevel.pdb_id
        })
      }
    }
    
    if (selectedRows.length === 0 && !!pdb) {
      setSelectedPosesForVisualization([{ pdb_id: pdb.name }])
    } else {
      
      setSelectedPosesForVisualization(selectedRows)
    }

  }, [rowSelection])

  return (<Fragment>
    <ResultItem title={"HydraScreen Predictions"}
                downloadHandler={showDownloadModal}>
      <ResultDescriptionItem>
        HydraScreen predictions can be downloaded from the button on the top left. {ligandDownloadLinkExists && 'All the docked poses for each ligand can be downloaded by clicking on the ligand id links in the table. Individual docked ligand poses can be downloaded by clicking the pose id links in the table.'}
      </ResultDescriptionItem>
      <ResultDescriptionItem title={"ligand affinity"}>
        Overall predicted weighted ligand affinity, expressed in pKi units, is obtained from the aggregation of the predicted pose affinities, weighted according to the Boltzmann distribution of the pose confidence score.
      </ResultDescriptionItem>
      <ResultDescriptionItem title={"pose confidence"}>
        Predicted pose confidence, ranging from low (0) to high (1), indicates the model's confidence that the pose could represent the real protein-ligand interaction. Note that this is solely based on the model's prediction and not a direct comparison with an existing experimental structure. 
      </ResultDescriptionItem>
      <ResultDescriptionItem title={"pose affinity"}> 
        Predicted affinity for the protein-ligand pose, expressed in pKi units. 
      </ResultDescriptionItem>
      <ResultDescriptionItem>Click on the help button <HelpOutlineIcon color="primary" fontSize="small" onClick={showVisualizationHelpModal} /> for more information about the visualization.</ResultDescriptionItem>
      {!!reference && <ResultDescriptionItem>
        <FormGroup>
          <FormControlLabel label="Show reference ligand" control={
            <Checkbox
              size="small"
              checked={showReferenceLigand}
              onChange={(e) => setShowReferenceLigand(e.target.checked)} />
          } />
        </FormGroup>
      </ResultDescriptionItem>}
      <div className="poseVisualizationDiv poseVisualizationDivActive">
        <Grid container maxWidth={"100vw"}>
          <Grid item xs={6}>
            <Title md sx={{ marginTop: "1.5%", marginBottom: "1.5%", textAlign: "left" }}>
              Table
              <Tooltip
                title={`Sort the columns by clicking on the column header. ${ligandDownloadLinkExists && 'All the docked poses for each ligand can be downloaded by clicking on the ligand id links in the table. Individual docked ligand poses can be downloaded by clicking the pose id links in the table.'}`}
                >
                <HelpIcon color="primary" fontSize="small" />
              </Tooltip>
            </Title>
          </Grid>
          <Grid item xs={6}>
            <Title md sx={{ marginTop: "1.5%", marginBottom: "1.5%", textAlign: "center" }}>
              Visualization
              <Tooltip
                title="Select poses below to visualize. Hold down primary mouse button to rotate the structure. Hold the secondary mouse button to pan. Scroll to zoom. Hold shift and scroll to slice. Click for more info.">
                <HelpOutlineIcon color="primary" fontSize="small" onClick={showVisualizationHelpModal} />
              </Tooltip>
            </Title>
          </Grid>
          <Grid item id="poseDataGridItem" xs={6} sx={{ height: "70vh" }}>
            <MaterialReactTable table={poseDataGridTable}/>
          </Grid>
          <Grid item sx={{ width: "50%" }} xs={6} id="visualizationGridItem">

            <Visualization3D proteinLigandPairs={proteinLigandPairs}
                             selectedPosesForVisualization={selectedPosesForVisualization} 
                             reference={showReferenceLigand ? reference : null} 
                             inferenceResults={inferenceResults.inference_results} />
          </Grid>
        </Grid>
      </div>
    </ResultItem>
    {modal && <Modal
    onClose={() => setModal(null)}
    width={"80%"}
    title={modal.title}
    open={true}>{modal.description}</Modal>}
  </Fragment>)
}

export default AppResultsTables

