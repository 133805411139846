import { Box, Grid, Link, Typography } from "@mui/material"
import { Fragment, useState } from "react"
import { Modal } from "./Modal"
import { FileFormatExampleTable } from "./FileFormatExampleTable"
import { PaperResultsTables } from "./paper_tool/ResultsTables"
import { AppResultsTables } from "./app/ResultsTables"
import Title from "./Title"
import { proteinLigandPairsTypes, InferenceResultsType } from '../utils'
import * as PropTypes from "prop-types"
import { CsvDescriptionAndDownload } from "./CsvDescriptionAndDownload"


ResultsSection.propTypes = {
  inferenceResults: InferenceResultsType.isRequired,
  proteinLigandPairs: proteinLigandPairsTypes,
  pdb: PropTypes.instanceOf(File),
  reference: PropTypes.instanceOf(File)
};

export function ResultsSection ({ inferenceResults, proteinLigandPairs, pdb, reference, app}) {  
  const [csvDescriptionModelIsOpen, setcsvDescriptionModelIsOpen] = useState(false)

  return (<Fragment>
    <Box className="results-section">
      <Grid container alignItems={"center"}>
        <Grid item xs={6} alignItems="flex-start">
          <Title xl sx={{textAlign: "left", color: "primary.main"}}>Results</Title>
        </Grid>
        {!app &&
          <Grid item xs={6} sx={{textAlign: "end"}}>
            <Typography variant="p" >
              <Link component="button" 
                    onClick={() => setcsvDescriptionModelIsOpen(true)}>
                CSV field description
              </Link>
            </Typography>
          </Grid>
        }
      </Grid>
      {app ? <AppResultsTables inferenceResults={inferenceResults} proteinLigandPairs={proteinLigandPairs} pdb={pdb} reference={reference} /> : 
      <PaperResultsTables inferenceResults={inferenceResults} proteinLigandPairs={proteinLigandPairs} pdb={pdb} reference={reference} /> }
    </Box>
    <Modal
        onClose={() => setcsvDescriptionModelIsOpen(false)}
        title="CSV Field Description"
        width={"80vw"}
        open={csvDescriptionModelIsOpen}> 
        <CsvDescriptionAndDownload/>
    </Modal>
  </Fragment>
  )
}

export default ResultsSection