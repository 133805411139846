import {default as MuiModal} from "@mui/base/Modal"
import { Backdrop, Fade, Paper, Typography } from "@mui/material"
import * as PropTypes from "prop-types"
import { Title } from "../components/Title.jsx";


Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool
}

Modal.defaultProps = {
    open: false,
    width: 400
}

export function Modal ({ onClose, title, children, open, width }) {
  return (<MuiModal
    aria-labelledby="transition-modal-title"
    aria-describedby="transition-modal-description"
    open={open}
    onClose={onClose}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
    slotProps={{
      backdrop: {
        timeout: 500,
        sx: {"z-index": 9998}
      },
    }}
  >
    <Fade in={open}>
      <Paper variant="outlined" sx={{
        my: { xs: 3, md: 6 },
        p: { xs: 2, md: 3 },
        borderRadius: "10px",
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: {width},
        backgroundColor: "white",
        zIndex: 9999,
        boxShadow: "4px 4px 8px 0px rgba(0, 0, 0, 0.15)"
      }}>
        <Title id="transition-modal-title" sx={{textAlign: "left"}}>
          {title}
        </Title>
        <Typography id="transition-modal-description" variant="div" sx={{ mt: 2 }}>
            {children}
        </Typography>
      </Paper>
    </Fade>
  </MuiModal>)
}
