import { InputStructure } from "../components/InputStructure"
import { InputStructureDescription } from "../components/InputStructureDescription"
import { Grid, CircularProgress } from "@mui/material"
import { StyledButton } from "../components/StyledButton"
import { StyledTextInput } from "../components/StyledTextInput"
import { AuthenticationContext } from "../context"
import { useParams } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import { api } from "../api.js"
import * as PropTypes from "prop-types"

LoginInput.propTypes = {
  emailAcitivityState: PropTypes.string.isRequired,
  emailInputDisabled: PropTypes.bool.isRequired,
  inputNumber: PropTypes.number.isRequired,
  onEmailSubmitSuccess: PropTypes.func,
  onEmailSubmitError: PropTypes.func,
  onAfterLogout: PropTypes.func,
  noBorder: PropTypes.bool,
}

export function LoginInput({emailAcitivityState, 
                            emailInputDisabled, 
                            inputNumber,
                            onEmailSubmitSuccess = () => {},
                            onEmailSubmitError = () => {},
                            onAfterLogout = () => {},
                            noBorder = false}) {

    const authContext = useContext(AuthenticationContext)
    const { runId } = useParams()
    const userLoggedIn = authContext.isUserLoggedIn()
    const [emailLoading, setEmailLoading] = useState(false)
    const [emailInputValue, setEmailInputValue] = useState("")

    useEffect(() => {
        if (authContext.user) {
            setEmailInputValue(authContext.user.email)
        } else {
            setEmailInputValue("")
        }
    }, [authContext.user])

    async function handleEmailSubmit () {
        setEmailLoading(true)
        try {
            await api.sendAuthenticationEmail({
                email: emailInputValue,
                organization: "no_organization"
            }, runId)

            setEmailLoading(false)
            onEmailSubmitSuccess()
        } catch (error) {
            setEmailLoading(false)
            onEmailSubmitError(error)
        }
    }

    function handleLogout() {
        authContext.logout()
        onAfterLogout()
    }

    const EmailAuthenticationButton = () => {

        const onClick = userLoggedIn ? handleLogout : handleEmailSubmit
        const secondaryColor = !userLoggedIn
        const text = userLoggedIn ? "Log out" : "Register"
        const disabled = userLoggedIn ? false : emailInputDisabled
    
        return <StyledButton secondaryColor={secondaryColor}
                             size="small"
                             onClick={onClick}
                             disabled={disabled}
                             sx={{ width: "160px", marginTop: "2.5rem" }}
        >
          {emailLoading ? <CircularProgress size="22.75px" /> : text}
        </StyledButton>
    }


    return <InputStructure inputNumber={inputNumber}
            title={"Register"}
            activityState={emailAcitivityState}
            noBorder={noBorder} >
        <InputStructureDescription>
            Log in using your email.
        </InputStructureDescription>

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <StyledTextInput
                value={emailInputValue}
                placeholder={"Enter your email"}
                disabled={emailInputDisabled || emailLoading}
                onChange={(e) => setEmailInputValue(e.target.value)}
                onKeyDown={(e) => {if (e.keyCode === 13) { handleEmailSubmit()}}}
                />
            </Grid>
            <Grid item xs={2}>
                <EmailAuthenticationButton />
            </Grid>
        </Grid>

    </InputStructure>

}
