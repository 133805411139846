import { Fragment, useState } from "react"
import { UploadProteinLigands } from "./UploadProteinLigands"
import { Box, CircularProgress, Grid, Typography } from "@mui/material"
import { StyledButton } from "../StyledButton"
import { Auth, api } from "../../api"
import * as PropTypes from "prop-types"
import { Modal } from "../Modal"

SubmitJob.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
  onInferenceResults: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onNewProteinLigandPairs: PropTypes.func.isRequired,
  submitPageDone: PropTypes.bool.isRequired,
  setSubmitPageDone: PropTypes.func.isRequired
}

SubmitJob.defaultProps = { disabled: false }

function SubmitJob ({ auth, onInferenceResults, disabled, onNewProteinLigandPairs, submitPageDone, setSubmitPageDone }) {
  const [proteinLigandPairs, setProteinLigandPairs] = useState([])
  const [fileUploadLoading, setFileUploadLoading] = useState(false)
  const [inferenceLoading, setInferenceLoading] = useState(false)
  const [errorModal, setErrorModal] = useState(null)

  const onFileUploaded = (newProteinLigandPairs, newProteinLigandFiles, pdb) => {
    setProteinLigandPairs(newProteinLigandPairs)
    setFileUploadLoading(false)
    onNewProteinLigandPairs(newProteinLigandFiles, pdb)
  }

  const beforeFileUpload = () => {
    setFileUploadLoading(true)
  }

  const runInference = async () => {
    try {
      setInferenceLoading(true)
      let responseData = await api.runInference(auth, proteinLigandPairs)
      onInferenceResults(responseData)
      setSubmitPageDone(true)
    } catch (e) {
      setErrorModal({ title: "Failed to run inference", description: e.message })
      setSubmitPageDone(true)
    } finally {
      setInferenceLoading(false)
    }
  }

  return (<Fragment>
    <UploadProteinLigands auth={auth} disabled={disabled} beforeFileUpload={beforeFileUpload}
                          onFileUploaded={onFileUploaded} 
                          submitPageDone={submitPageDone} setSubmitPageDone={setSubmitPageDone} />
    {!submitPageDone &&
    <Grid container>
      <Grid item xs={2}>
        <Box style={{width: "160px"}}>
          <StyledButton secondaryColor={true} 
                            size="small"
                            onClick={runInference}
            disabled={disabled || inferenceLoading || fileUploadLoading || proteinLigandPairs.length === 0}
          >
            {inferenceLoading ? <CircularProgress size="22.75px" /> : "Submit"}
          </StyledButton>
        </Box>
      </Grid>
      <Grid item xs={10}>
        {inferenceLoading && <Typography>This might take up to a minute</Typography>}
      </Grid>
    </Grid>
    }
    {errorModal && <Modal
      onClose={() => setErrorModal(null)}
      title={errorModal.title}
      open={true}>{ errorModal.description }</Modal>}
  </Fragment>)
}

export default SubmitJob