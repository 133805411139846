import { Container, Typography, Link, Stack } from "@mui/material";
import { api } from "../api";
import { Title } from "../components/Title.jsx";
import { StyledButton } from "../components/StyledButton";
import { FeedbackButton } from "../components/FeedbackButton";
import { PageStructure } from "../pages/PageStructure.jsx";

const Paragraph = ({ children }) => (
  <Typography sx={{ color: "black", fontSize: "1.5rem" }}>{children}</Typography>
);

function AboutPage() {
  async function downloadRedockedDataset() {
    const url = await api.getRedockedDatasetDownloadUrl();
    window.open(url);
  }

  return (
    <PageStructure title="About HydraScreen">
      <Container
        disableGutters
        maxWidth="md"
        component="main"
        sx={{ pt: 8, pb: 6 }}
      >
        <Stack direction="column" spacing={3}>
          <Title xl align="left">
            About HydraScreen
          </Title>
          <Paragraph>
            HydraScreen is based on a state-of-the-art model that uniquely employs
            a multimodal hybrid architecture combining 3D-convolutional neural
            networks (CNNs) and transformers to efficiently encode protein-ligand
            complexes and their interactions. It was trained on protein-ligand
            co-crystal structures and docked poses through a proprietary procedure
            based on PDBbind data.
          </Paragraph>
          <Paragraph>
            We have demonstrated the increased accuracy of HydraScreen compared to
            traditional methods, such as docking, through public benchmarks and
            prospective validation studies. More detailed information about the
            model and its performance is available in our{" "}
            <a
              href="https://doi.org/10.26434/chemrxiv-2023-gwm1s-v2"
              target="_blank"
            >
              publication
            </a>
            . You can access RD-2020, the re-docked dataset used to train and
            evaluate HydraScreen,{" "}
            <a href="#" onClick={() => downloadRedockedDataset()}>
              here
            </a>. HydraScreen is also available through our open-source <a href="https://github.com/Ro5-ai/hydrascreen" target="_blank">Python API.</a>
          </Paragraph>
          <StyledButton
            secondaryColor={true}
            href="/paper"
            sx={{width: "260px"}}
            >
            Visit the publication model App
          </StyledButton>
          <Paragraph>
            For more information about Ro5 AI-powered drug discovery software
            visit{" "}
            <Link href="https://www.ro5.ai" target="_blank">
              www.ro5.ai
            </Link>.
          </Paragraph>
          <Stack direction="row" spacing={1} alignItems="end">
            <StyledButton
              secondaryColor={true}
              href="mailto:support@ro5.ai?subject=Regarding HydraScreen"
              sx={{width: "160px"}}
              >
              Contact us
            </StyledButton>
            <FeedbackButton text="Leave feedback" sx={{width: "160px"}} />
          </Stack>
        </Stack>
      </Container>
    </PageStructure>
  );
}

export default AboutPage;
