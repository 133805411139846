import { Button } from "@mui/material"
import PropTypes from "prop-types"
import { mergeDeep } from "../utils"
import { forwardRef } from "react"

const DEFAULT_BUTTON_STYLE = {
  "borderRadius": "100px",
  "textTransform": "none",
  "color": "primary.main",
  "backgroundColor": "primary.contrastText",
  "&:hover": {
    backgroundColor: "primary.contrastText",
    color: "primary.main"
  },
  "width": "100%",
}

const SECONDARY_BUTTON_STYLE = {
  "borderRadius": "100px",
  "textTransform": "none",
  "color": "primary.contrastText",
  "backgroundColor": "primary.main",
  "&:hover": {
    backgroundColor: "primary.main",
    color: "primary.contrastText"
  },
  "width": "100%",
  ".MuiCircularProgress-root": {
    color: "white"
  }
}

export const StyledButton = forwardRef(({ sx, children, onClick, disabled, startIcon, endIcon, secondaryColor, href, download, className }, ref) => {
  let activeSx = {}
  if (secondaryColor) {
    activeSx = mergeDeep(SECONDARY_BUTTON_STYLE, sx)
  } else {
    activeSx = mergeDeep(DEFAULT_BUTTON_STYLE, sx)
  }

  return (
    <Button
      variant="contained"
      size={"small"}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled}
      sx={activeSx}
      href={href}
      download={download}
      className={className}
      ref={ref}
    >
      {children}
    </Button>
  )
})

StyledButton.propTypes = {
  sx: PropTypes.object,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
  secondaryColor: PropTypes.bool,
  href: PropTypes.string,
  download: PropTypes.string,
  className: PropTypes.string
}

StyledButton.defaultProps = {
  sx: {},
  disabled: false,
  startIcon: null,
  endIcon: null,
  secondaryColor: false,
  href: null,
  download: null,
  className: ""
}
