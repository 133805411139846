import { createTheme, responsiveFontSizes } from "@mui/material"

let theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#0050A9',
      light: '#3373BA',
      dark: '#004087',
    },
    secondary: {
      main: '#12AFE1',
      light: '#41BFE7',
      dark: '#0E8CB4',
    },
    info: {
      main: '#0050A9',
      light: '#3373BA',
      dark: '#004087',
    },
    error: {
      main: '#D30305',
      light: '#DC3537',
      dark: '#A90204',
    },
    warning: {
      main: '#FF5C00',
      light: '#FF7D33',
      dark: '#CC4A00',
    },
    success: {
      main: '#1D9C8B',
      light: '#4AB0A2',
      dark: '#177D6F',
    },
    custom: {
      sidebar: "#1976D2",
      background: "#F3F4F6",
      geneSearchField: "#4790DA",
      disabledText: "rgba(0,0,0,0.4)"
    }
  },
  typography: {
    fontFamily: "Rubik",
  },
})

theme = responsiveFontSizes(theme)


export const SIDEBAR_WIDTH = "17.7vw"
export const CONTENT_WIDTH = "82.3vw"
export const TWO_COLUMN_CONTENT_WIDTH = "52.7vw"


export default theme;