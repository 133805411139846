import PropTypes from "prop-types"
import { TextField } from "@mui/material";

const DEFAULT_INPUT_SX = {
    borderRadius: "4px",
    background: "white",
    padding: "10px",
    alignItems: "center"
}

StyledTextInput.propTypes = {
    value: PropTypes.any,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    sx: PropTypes.object,
    onKeyDown: PropTypes.func
}

StyledTextInput.defaultProps = {
    value: null,
    placeholder: "",
    onChange: () => {},
    disabled: false,
    sx: DEFAULT_INPUT_SX,
    onKeyDown: () => {}
}

export function StyledTextInput({value, placeholder, onChange, disabled, sx, onKeyDown}) {
    const activeSx = {...DEFAULT_INPUT_SX, ...sx}

    const uploaded = !!value;
    const activeClassName = uploaded ? "file-input-upload pdb-file-upload-input file-input-upload-uploaded" : disabled ? "file-input-upload pdb-file-upload-input file-input-upload-disabled" : 'file-input-upload pdb-file-upload-input';

    return (
        <TextField
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            variant={"standard"}
            fullWidth={true}
            className={activeClassName}
            sx={activeSx}
            onKeyDown={onKeyDown}
        />
    )
}
