import { Grid } from "@mui/material"
import { InferenceResultsType } from "../utils"
import { base64DataString, objectToCsv } from "../utils";
import { StyledButton } from "./StyledButton"
import DownloadIcon from '@mui/icons-material/Download';
import Title from "../components/Title"
import { FileFormatExampleTable } from './FileFormatExampleTable'
import PropTypes from "prop-types"

CsvDescriptionAndDownload.propTypes = {
    includeDownload: PropTypes.bool,
    inferenceResults: InferenceResultsType
}

CsvDescriptionAndDownload.defaultTypes = {
    includeDownload: false
}

export function CsvDescriptionAndDownload({includeDownload, inferenceResults}) {

    return <>
        <Grid container sx={{padding: 3}}>
            <Grid item xs={6} alignItems={"flex-start"}>
                <Title sx={{textAlign: "left"}} md>CSV file: ligand_affinity_predictions</Title>
            </Grid>
            {includeDownload &&
            <Grid item xs={6} textAlign={"end"}>
                <StyledButton startIcon={<DownloadIcon />} 
                                    href={base64DataString(objectToCsv(inferenceResults["inference_ligand_summary"]))}
                                    download={"ligand_affinity_predictions.csv"}
                                    sx={{width: "160px", 
                                        border: "1px solid", 
                                        borderColor: "primary.main",
                                        marginBottom: "10px", 
                                        boxShadow: "0", 
                                        "&:hover": {boxShadow: "0"}
                                    }}>Download CSV</StyledButton>

            </Grid>}
            <Grid item xs={12} alignItems={"flex-start"}>
                <FileFormatExampleTable textArray={
                    [['pdb_id', 'Name of the protein the ligands are docked to (provided protein PDB file name).'],
                    ['ligand_id', 'Name of the ligand docked to the pdb_id protein (provided ligand SDF file name).'],
                    ['ligand_affinity', 'Overall ligand affinity, expressed in pKi units, is obtained from the aggregation of the predicted pose affinities, weighted according to the Boltzmann distribution of the pose confidence score.']]
                }/>
            </Grid>
        </Grid>

        <Grid container sx={{padding: 3}}>
            <Grid item xs={6} alignItems={"flex-start"}>
                <Title sx={{textAlign: "left"}} md>CSV file: pose_predictions</Title>
            </Grid>
            {includeDownload && <Grid item xs={6} textAlign={"end"}>
                <StyledButton startIcon={<DownloadIcon />} 
                                href={base64DataString(objectToCsv(inferenceResults["inference_results"]))}
                                download={"pose_predictions.csv"}
                                sx={{width: "160px", 
                                    border: "1px solid", 
                                    borderColor: "primary.main", 
                                    marginBottom: "10px", 
                                    boxShadow: "0", 
                                    "&:hover": {boxShadow: "0"}
                                }}>Download CSV</StyledButton>
            </Grid>}
            <Grid item xs={12} alignItems={"flex-start"}>
                <FileFormatExampleTable textArray={
                    [['pdb_id', 'Name of the protein the ligands are docked to (provided protein PDB file name).'],
                    ['ligand_id', 'Name of the ligand docked to the pdb_id protein (provided ligand SDF file name).'],
                    ['pose_id', 'Sequential pose number based on the order of the docked ligand poses in the SDF file.'],
                    ['pose_confidence', 'Pose confidence, ranging from low (0) to high (1), indicates the model\'s confidence that the pose could be the true, protein-ligand co-crystal structure. Note that this is solely based on the model\'s prediction and not a direct comparison with an existing co-crystal structure.'],
                    ['pose_affinity', 'Predicted affinity of the protein-pose pair, expressed in pKi units.']]
                }/>
            </Grid>
        </Grid>
    </>
}