import { Alert } from "@mui/material";
import PropTypes from "prop-types";

CustomAlerts.propTypes = {
    alerts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    severity: PropTypes.oneOf(["error", "warning", "info", "success"]).isRequired,
};

export function CustomAlerts({ alerts, severity }) {
    return (
        <>
            {alerts.map((alert, i) => (
                <Alert key={i} severity={severity} sx={{ mb: "3px" }}>
                    {alert}
                </Alert>
            ))}
        </>
    );
}

