import { Link, Typography, Container } from "@mui/material"
import { Fragment } from "react"

const DEFAULT_LINK_SX = {
  color: "black",
  textAlign: "center",
  fontFamily: "Rubik",
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: "300",
  lineHeight: "normal",
  textDecorationLine: "underline",
  textDecorationColor: "black"
}

const Footer = () => {
  return (
  <Container disableGutters maxWidth="md" component="main" sx={{ pt: 8, pb: 6 }}>
    <Fragment>
    <Typography align="left" variant="body2" color="black" sx={{"bgcolor": "custom.background", pt: "1rem", pb: "1rem"}}>
      <Link href="/terms_and_conditions" sx={DEFAULT_LINK_SX}>Terms of Service</Link>{" | "}
      <Link href="/privacy_policy" sx={DEFAULT_LINK_SX}>Privacy Policy</Link>{" | "}
      {"Copyright © "}
      <Link href="https://ro5.ai" sx={DEFAULT_LINK_SX}>
        Ro5 Inc.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  </Fragment>
  </Container>)
}

export default Footer
