import { getUserToken, parseUserTokenData } from "./utils"

export const API_ROOT = process.env.NODE_ENV === "development" ? "http://localhost:8000/v2" : "https://hydrascreen-api.ro5.ai/v2"

export class Auth {
  constructor (token) {
    const tokenData = parseUserTokenData(token)
    this.token = token
    this.email = tokenData.email
    this.organization = tokenData.org
  }
}

async function uploadProteinWithReferencePdb (auth, file) {
  let data = new FormData()
  data.append("file", file)
  let response = await fetch(`${API_ROOT}/upload-pdb-with-reference?email=${auth.email}`,
    {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${getUserToken()}`
      },
      body: data
    })
  if (response.status !== 200) {
    throw new Error((await response.json())["detail"])
  }
  return await response.json()
}

async function uploadProteinPdb (auth, file) {
  let data = new FormData()
  data.append("file", file)
  let response = await fetch(`${API_ROOT}/upload-pdb?email=${auth.email}`,
    {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${auth.token}`
      },
      body: data
    })
  if (response.status !== 200) {
    throw new Error((await response.json())["detail"])
  }
  return await response.json()
}

async function uploadLigandFile (auth, parentPdbS3Path, file, checkIfDocked=false) {
  let data = new FormData()
  data.append("file", file)
  let response = await fetch(`${API_ROOT}/upload-ligand?email=${auth.email}&pdb_s3_path=${parentPdbS3Path}&check_if_docked=${checkIfDocked}`,
    {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Authorization": `Bearer ${auth.token}`
      },
      body: data
    })
  if (response.status !== 200) {
    throw new Error((await response.json())["detail"])
  }
  return await response.json()
}

async function runInference (auth, proteinLigandPairs) {
  let response = await fetch(`${API_ROOT}/invocation`,
    {
      method: "post",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${auth.token}`
      },
      body: JSON.stringify({
        email: auth.email,
        organization: auth.organization,
        inference_pairs: proteinLigandPairs
      })
    })
  if (response.status !== 200) {
    throw new Error((await response.json())["detail"])
  }
  return await response.json()
}

const sendAuthenticationEmail = async (auth, runId = null) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: auth.email,
      organization: auth.organization,
      runId: runId
    })
  }

  let response = await fetch(`${API_ROOT}/email/verify`, requestOptions)
  if (response.status !== 200) {
    throw new Error(`Unable to verify email. Detail: ${(await response.json()).detail}`)
  }
}

const postUserFeedback = async (auth, feedback) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${auth.token}`
    },
    body: JSON.stringify({
        feedback: feedback
    })
  }

  let response = await fetch(`${API_ROOT}/feedback/post`, requestOptions)
  if (response.status !== 200) {
    throw new Error(`Unable to send feedback. Detail: ${(await response.json()).detail}`)
  }
}

const postDockingJob = async (pdb_file_s3_path, ref_lig_files_data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getUserToken()}`
    },
    body: JSON.stringify({
        pdb_file: pdb_file_s3_path,
        ref_lig_files: ref_lig_files_data,
    })
  }

  let response = await fetch(`${API_ROOT}/docking/run`, requestOptions)
  if (response.status !== 200) {
    throw new Error(`Unable to run inference with docking. Detail: ${(await response.json()).detail}`)
  }
}

const getRedockedDatasetDownloadUrl = async () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Accept": "application/json",
    },
  }

  let response = await fetch(`${API_ROOT}/get-redocked-download-url`, requestOptions)
  if (response.status !== 200) {
    throw new Error(`Unable to fetch redocked dataset URL. Detail: ${(await response.json()).detail}`)
  }
  return await response.json()
}

const getFile = async (fileKey) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${getUserToken()}`
    },
  }

  let response = await fetch(`${API_ROOT}/get_file?file_key=${fileKey}`, requestOptions)
  if (response.status !== 200) {
    throw new Error(`Unable to fetch file. Detail: ${(await response.json()).detail}`)
  }
  return await response.text()
}

const getResultsStructure = async (runId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Accept": "application/json",
      "Authorization": `Bearer ${getUserToken()}`
    },
  }

  let response = await fetch(`${API_ROOT}/get_results?run_id=${runId}`, requestOptions)
  if (response.status === 401) {
    throw new Error('Unable to fetch results. Invalid access token')
  }
  if (response.status !== 200) {
    throw new Error(`Unable to fetch results. Detail: ${(await response.json()).detail}`)
  }
  return await response.json()
}

const fetchFile = async (url, fileType) => {
  let response = await fetch(url)
  if (response.status !== 200) {
    throw new Error(`Unable to fetch file. Detail: ${(await response.json()).detail}`)
  }
  if (fileType === "pdb" || fileType === "sdf") {
    let blob = await response.blob()
    let fileName = url.split("?")[0].split("/").pop()
    return new File([blob], fileName, {type: "application/octet-stream"})
  } else {
    return response.text()
  }
}

export let api = {
  getRedockedDatasetDownloadUrl,
  postDockingJob,
  postUserFeedback,
  runInference,
  sendAuthenticationEmail,
  uploadLigandFile,
  uploadProteinPdb,
  uploadProteinWithReferencePdb,
  getFile,
  getResultsStructure,
  fetchFile
}
