import { Modal } from "../components/Modal";
import { CustomAlerts } from "../components/CustomAlerts.js";
import { TextField, Box } from "@mui/material";
import { Auth, api } from "../api.js";
import { useState, useContext } from "react";
import { StyledButton } from "../components/StyledButton.jsx";
import { AuthenticationContext } from "../context.js";
import PropTypes from "prop-types";
import { getPaperAppletUserToken } from "../utils.js";

FeedbackModal.propTypes = {
  feedbackModalIsOpen: PropTypes.bool,
  setFeedbackModalIsOpen: PropTypes.func.isRequired
}

FeedbackModal.defaultProps = {
  feedbackModalIsOpen: false
}

export function FeedbackModal({feedbackModalIsOpen, 
                            setFeedbackModalIsOpen}) {
  const [userFeedback, setUserFeedback] = useState("")
  const [feedbackErrors, setFeedbackErrors] = useState([])
  const [feedbackSuccessMessages, setFeedbackSuccessMessages] = useState([])
  const [feedbackIsSending, setFeedbackIsSending] = useState(false)
  const authContext = useContext(AuthenticationContext)
  const feedbackUser = authContext.user != null ? authContext.user : new Auth(getPaperAppletUserToken())

  const removeUserFeedbackTextAndCloseModal = () => {
    setUserFeedback("");
    setFeedbackErrors([]);
    setFeedbackSuccessMessages([]);
    setFeedbackModalIsOpen(false);
  }

  const handleSubmitUserFeedback = async (feedback) => {
    setFeedbackIsSending(true)
    setFeedbackErrors([])
    setFeedbackSuccessMessages([])
    try {
      await api.postUserFeedback(feedbackUser, feedback)
      setFeedbackSuccessMessages(["Thank you for your feedback!"])
      setUserFeedback("")
    } catch (error) {
      setFeedbackErrors([error.message])
    }
      setFeedbackIsSending(false)
  }

  return <Modal aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={feedbackModalIsOpen} 
                width={600} 
                title="Feedback"
                onClose={removeUserFeedbackTextAndCloseModal}
                >

        <CustomAlerts alerts={feedbackErrors} severity="warning" />
        <CustomAlerts alerts={feedbackSuccessMessages} severity="success" />

        <TextField
            multiline
            rows={5}
            value={userFeedback}
            onChange={(evt) => setUserFeedback(evt.target.value)}
            sx={{
                width: "100%",
                borderRadius: "10px",
                background: "#F3F4F6",
                margin: "10px 0 20px 0"
            }}
        />
        <Box sx={{width: "160px"}}>
            <StyledButton
                disabled={!userFeedback.trim() || userFeedback.trim().length < 3 || feedbackIsSending}
                onClick={() => handleSubmitUserFeedback(userFeedback)}
                secondaryColor={true}
            >
                Submit
            </StyledButton>
        </Box>
  </Modal>
}

export default FeedbackModal;
