import { AppBar, Button, Toolbar, Typography, Stack } from "@mui/material"
import Ro5Logo from "../assets/ro5_logo.svg"
import { useNavigate } from "react-router-dom"
import PropTypes from "prop-types"
import FeedbackButton from "../components/FeedbackButton.jsx"

const NavbarButton = ({handleClick = () => {}, text, href = "", target = ""}) => {
  return (
    <Button color="inherit" variant="text" size="small"
      sx={{ ml: "0.15rem", mr: "0.15rem", pl: "0.25rem" , pr: "0.25rem", fontWeight: "400", minWidth: "90px", textTransform: "none" }}
      onClick={handleClick} href={href} target={target}>{text}</Button>
  )
}

NavbarButton.propTypes = {
  handleClick: PropTypes.func, 
  text: PropTypes.string.isRequired,
  href: PropTypes.string,
  target: PropTypes.string
}

export function Navbar () {
  const navigate = useNavigate()

  return (
    <AppBar position="static" component="nav" elevation={0} sx={{backgroundColor: "custom.sidebar"}}>
      <Toolbar sx={{px: "2.5%"}}>
        <img  style={{cursor: "pointer"}} width="95px" height="95px" src={Ro5Logo} alt="Ro5" onClick={() => {navigate("/")}} />
        <Stack direction="column" pl="1rem">
          <Typography variant="h5" sx={{ cursor: "pointer"}} onClick={() => {navigate("/")}}>HydraScreen</Typography>
          <Typography sx={{ cursor: "pointer"}} onClick={() => {navigate("/")}}>Protein-ligand affinity and pose score predictor</Typography>
        </Stack>
        <Stack direction="row" display="flex" flex={1} justifyContent="flex-end">
          <NavbarButton handleClick={() => navigate("/about")} text={"ABOUT"} />
          <NavbarButton href={"https://medium.com/@ro5.ai"} target={"_blank"} text={"BLOG"} />
          <NavbarButton href={"mailto:support@ro5.ai?subject=Regarding HydraScreen"} text={"CONTACT US"} />
          <NavbarButton href={"https://www.ro5.ai"} target={"_blank"} text={"Ro5"} />
        </Stack>
        <FeedbackButton sx={{ boxShadow: "2px 2px 4px 0px rgba(0, 0, 0, 0.15)", width: null, marginTop: 0 }}/>
      </Toolbar>
    </AppBar>
    )
}

export default Navbar;
