import { Box } from "@mui/material"
import { useEffect } from "react"
import { CONTENT_WIDTH } from "../theme.js"
import { Navbar } from  "../components/Navbar.js"
import PropTypes from "prop-types"
import Footer from "../components/Footer"

PageStructure.propTypes = {
  title: PropTypes.string,
  contentWidth: PropTypes.string
}

PageStructure.defaultProps = {
  contentWidth: CONTENT_WIDTH
}

export function PageStructure({children, contentWidth, title}) {

  useEffect(() => { if (title != null) { document.title = title } }, [title])

  return (
    <Box sx={{ display: "flex", "bgcolor": "custom.background", minHeight: "100vh", flexDirection: "column"}}>
    <Navbar />  
      <Box sx={{display: "flex", flexDirection: "column", alignItems: "start", minHeight: "100vh"}}>
      

        <Box component="main" sx={{ width: contentWidth, pl: "4.4vw", pb: "0", pt: "2vw" }}>
          {children}
          <Footer />
        </Box>

      </Box>
      
    </Box>
  )
}