import { useEffect } from "react"
import { Container } from "@mui/material"

import LandingSlogan from "../components/LandingSlogan"
import { PageStructure } from "./PageStructure"
import UploadProteinLigands from "../components/app/UploadProteinLigands"

export default function HydrascreenSubmitJobPage() {

    useEffect(() => {document.title = "HydraScreen"}, [])

    return (
        <PageStructure  title="HydraScreen">
            <LandingSlogan />
            <Container component="main" maxWidth="md" sx={{ mb: 4, textAlign: 'center'}}>
                <UploadProteinLigands beforeFileUpload={() => {}} onFileUploaded={() => {}}/>
            </Container>
        </PageStructure>
    )
}
