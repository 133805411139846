import { Paper} from "@mui/material"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import * as PropTypes from "prop-types"

FileFormatExampleTable.propTypes = {
    textArray: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired
}

FileFormatExampleTable.defaultProps = {}
  

export function FileFormatExampleTable ({ textArray }) {
    return (
        <TableContainer sx={{boxShadow: "0"}} component={Paper}>
            <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                <TableBody>
                    {textArray.map((row, j) => ( 
                        <TableRow
                        sx={{ 'td': { borderBottom: "1px solid #949494" }, '&:first-of-type td': {borderTop: "1px solid #949494"} }} 
                        size='small' key={j}
                        >
                            {row.map((item, i) => ( 
                                <TableCell sx={{'&:first-of-type': {fontWeight: "500"}, 
                                                color: "black",
                                                fontSize: "1.125rem",
                                                lineHeight: "1.4"}} key={`${i}_${j}`}>{item}</TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
  }
