import { Box, Grid, Typography } from "@mui/material"
import PropTypes from "prop-types"
import Title from "../components/Title"
import CheckIcon from '@mui/icons-material/Check';

InputStructure.propTypes = {
    inputNumber: PropTypes.number,
    title: PropTypes.string,
    activityState: PropTypes.oneOf(["done", "active", "inactive"]),
    noBorder: PropTypes.bool
}

InputStructure.defaultProps = {
    inputNumber: 1,
    title: "",
    activityState: "inactive",
    noBorder: false
}

function getActivityStateColors(activityState) {
    let circleColor, titleColor, borderColor, circleBackground;

    switch (activityState) {
        case "inactive":
            circleColor = "#CDCDCD";
            titleColor = "#7E7D7D";
            borderColor = "#CDCDCD";
            circleBackground = "transparent";
            break;
        case "active":
            circleColor = "#0050A9";
            titleColor = "#0050A9";
            borderColor = "#CDCDCD";
            circleBackground = "transparent";
            break;
        case "done":
            circleColor = "#0050A9";
            titleColor = "#0050A9";
            borderColor = "#0050A9";
            circleBackground = "#0050A9";
            break;
        default:
            circleColor = "#0050A9";
            titleColor = "#0050A9";
            borderColor = "#0050A9";
            circleBackground = "transparent";
            break;
    }

    return {
        circleColor,
        titleColor,
        borderColor,
        circleBackground
    };
}


export function InputStructure({children, inputNumber, title, activityState, noBorder}) {

    const colors = getActivityStateColors(activityState);

    const NUMBER_CIRCLE_SX = {
        borderRadius: "50%",
        width: "58px",
        height: "58px",
        padding: "10px",
    
        border: `2px solid ${colors.circleColor}`,
        borderColor: colors.circleColor,
        backgroundColor: colors.circleBackground,
        textAlign: "center",
    }

    const CONTENTS_SX = {
        borderLeft: noBorder ? "none" : `2px solid ${colors.borderColor}`,
        marginLeft: noBorder ? "31px" : "29px",
        paddingLeft: "20px",
        paddingBottom: "7%",
        paddingTop: "5%",
        height: "100%"
    }

    return <Box>
            <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item xs={1}>
                    <Box className="numberCircle" style={NUMBER_CIRCLE_SX}>
                        {activityState === "done" ? <CheckIcon style={{color: "white", marginTop: "4px"}} />:
                        <Typography variant="h5" color={colors.titleColor} sx={{fontWeight: 500, textEdge:"cap", leadingTrim: "both"}}>{inputNumber}</Typography>}
                    </Box>
                </Grid>
                <Grid item sx={{display: "flex"}} xs={11}>
                    <Title lg sx={{alignSelf: "center", color: colors.titleColor}}>{title}</Title>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={CONTENTS_SX}>
                        {children}
                    </Box>
                </Grid>
            </Grid>
            
    </Box>
}

export default InputStructure;