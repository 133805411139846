import { Box, Grid } from "@mui/material"
import PropTypes from "prop-types"
import Title from "../components/Title"
import DownloadIcon from '@mui/icons-material/Download';
import { StyledButton } from "../components/StyledButton"
import { base64DataString, objectToCsv } from "../utils";

ResultItem.propTypes = {
    title: PropTypes.string.isRequired,
    dataDownload: PropTypes.any,
    downloadName: PropTypes.string,
    downloadHandler: PropTypes.func
}  

export function ResultItem({children, title, dataDownload, downloadName, downloadHandler}) {
    return (<Box className="result-item-box">
        <Grid container>
            <Grid item xs={6} alignItems={"flex-start"}>
                <Title sx={{textAlign: "left"}}>{title}</Title>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
                {!!downloadHandler ? <StyledButton startIcon={<DownloadIcon />} 
                                onClick={downloadHandler}
                                sx={{width: "260px", 
                                    border: "1px solid", 
                                    borderColor: "primary.main", 
                                    boxShadow: "0", 
                                    "&:hover": {boxShadow: "0"}
                                }}>Download Predictions</StyledButton> :
                <StyledButton startIcon={<DownloadIcon />} 
                                href={base64DataString(objectToCsv(dataDownload))}
                                download={downloadName}
                                sx={{width: "160px", 
                                    border: "1px solid", 
                                    borderColor: "primary.main", 
                                    boxShadow: "0", 
                                    "&:hover": {boxShadow: "0"}
                                }}>Download CSV</StyledButton>
                }
            </Grid>
        </Grid>
        {children}
    </Box>)
}