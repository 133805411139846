import { Typography } from "@mui/material"
import PropTypes from "prop-types"

const DEFAULT_INPUT_SX = {
    color: "black",
    fontSize: "1.125rem",
    lineHeight: "140%",
    marginTop: "1.9%",
    marginBottom: "1.9%"
}

InputStructureDescription.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object
}

InputStructureDescription.defaultProps = {
    sx: DEFAULT_INPUT_SX
}

export function InputStructureDescription({children, sx}) {

    const activeSx = {...DEFAULT_INPUT_SX, ...sx}

    return (
        <Typography gutterBottom variant="p" 
                align="left" 
                component="p" 
                sx={activeSx}>{children}</Typography>
    )
}

export default InputStructureDescription;