import { useEffect, useRef, useState } from "react"
import { Container } from "@mui/material"

import { exampleInferenceResults, getPaperAppletUserToken } from "../utils"
import SubmitJob from "../components/paper_tool/SubmitJob"
import ResultsSection from "../components/ResultsSection"
import LandingSlogan from "../components/LandingSlogan"
import { PageStructure } from "./PageStructure"
import { Auth } from "../api"

function PaperToolPage () {
  const [inferenceResults, setInferenceResults] = useState(process.env.NODE_ENV === "development" ? exampleInferenceResults : null)
  const resultSectionRef = useRef()
  const [proteinLigandPairs, setProteinLigandPairs] = useState(null);
  const [pdb, setPdb] = useState(null)
  const [submitPageDone, setSubmitPageDone] = useState(false)
  const paperAppletUser = new Auth(getPaperAppletUserToken())

  useEffect(() => {document.title = "HydraScreen"}, [])

  const onInferenceResults = (results) => {
    setInferenceResults(results)
    resultSectionRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const onNewProteinLigandPairs = (newProteinLigandPairs, pdb) => {
      setProteinLigandPairs(newProteinLigandPairs)
      setPdb(pdb)
      if (!newProteinLigandPairs || (!!newProteinLigandPairs && newProteinLigandPairs.length < 1)) {
        setInferenceResults(null)
      }
  }

  return (
    <PageStructure  title="HydraScreen">
      <LandingSlogan />
      <Container component="main" maxWidth="md" sx={{ mb: 4, textAlign: 'center' }}>
          <SubmitJob auth={paperAppletUser}
                     onInferenceResults={onInferenceResults}
                     disabled={false}
                     onNewProteinLigandPairs={onNewProteinLigandPairs}
                     submitPageDone={submitPageDone}
                     setSubmitPageDone={setSubmitPageDone} />
          <div ref={resultSectionRef}>
            {!!inferenceResults && (!!proteinLigandPairs && proteinLigandPairs.length > 0) && <ResultsSection inferenceResults={inferenceResults}
                                                                                                              proteinLigandPairs={proteinLigandPairs} pdb={pdb}/>}
          </div>
      </Container>
    </PageStructure>
  )
}

export default PaperToolPage
