import { useParams, useNavigate, useLocation  } from "react-router-dom"
import { Grid } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { useEffect } from "react"
import { setUserToken } from "../utils"

export function TokenPage () {

  const { userToken } = useParams()
  const navigate = useNavigate()
  const location = useLocation();

  useEffect(() => {
    handleToken(userToken)
  }, [userToken, navigate])

  function handleToken () {
    const redirect = new URLSearchParams(location.search).get('redirect');
    setUserToken(atob(userToken))
    if (redirect) {
      navigate(redirect)
    } else {
      navigate("/submit_job")
    }
  }

  return <>
    <Grid container direction="column" alignItems="center" width="100%" spacing={3}>
      <Grid item>
        <CircularProgress sx={{ mt: "50%" }} />
      </Grid>
    </Grid>
  </>
}

