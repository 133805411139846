import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow
} from "@mui/material/colors"
import * as PropTypes from "prop-types"

export const validateEmail = (email) => {
  const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/ // regex from: https://www.w3docs.com/snippets/javascript/how-to-validate-an-e-mail-using-javascript.html
  return emailRegex.test(email)
}

export const getPaperAppletUserToken = () => {
  if (window.location.hostname === "hydrascreen.ro5.ai") {
    return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImV4dGVybmFsX3VzZXJfZW1haWxAcGFwZXItdG9vbC5ybzUuYWkiLCJvcmciOiJFeHRlcm5hbCB1c2VycyJ9.grTHxgRS__vOvJ77m1IXWeiG5uYU_A6lJyYpz0V4LWk"
  }
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlbXBAcm81LmFpIiwib3JnIjoiUm81In0.A8Wtd4vXOotqf6JxMJXWgte2Sxj-4iV2-cGCrBCiaHw"
}

export const getUserToken = () => {
  return localStorage.getItem("userToken")
}

export function parseUserTokenData (token) {
  return JSON.parse(atob(token.split(".")[1]))
}

export function isUserTokenValid (userToken) {
  try {
    let currentTimestamp = + new Date()
    let tokenData = parseUserTokenData(userToken)
    return currentTimestamp < (tokenData.exp * 1000)
  } catch (e) {
    return false
  }
}

export const setUserToken = (userToken) => {
  localStorage.setItem("userToken", userToken)
}

async function loadMultipleFiles(filePaths) {
  const loadedFiles = {};

  await Promise.all(
    filePaths.map(async (filePath) => {
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`Failed to load file: ${filePath}`);
        }

        const blob = await response.blob();
        loadedFiles[filePath] = new File([blob], filePath.replaceAll("./assets/", ""));
      } catch (error) {
        console.error('Error loading file:', error);
        loadedFiles[filePath] = null; // Handle the error as needed
      }
    })
  );

  return loadedFiles;
}

export async function loadExamplePDBSDF() {
  const filePaths = [
    './assets/6tpd_protein_clean.pdb',
    './assets/6tpd_protein_clean_6tpd_jak1-7_0_conformer.sdf',
    './assets/6tpd_protein_clean_6tpd_jak1-1_0_conformer.sdf',
  ];

  try {
    const loadedFiles = await loadMultipleFiles(filePaths);

    const pdb = loadedFiles[filePaths[0]]
    const sdfs = [loadedFiles[filePaths[1]], loadedFiles[filePaths[2]]]

    return {examplePdb: pdb, exampleSdfs: sdfs};

  } catch (error) {
    console.error('Error loading files:', error);
    throw error;
  }
}

export const exampleInferenceResults = {
  "inference_results": [
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "0",
          "pose_confidence": 0.975,
          "pose_affinity": 5.162
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "1",
          "pose_confidence": 0.582,
          "pose_affinity": 4.275
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "2",
          "pose_confidence": 0.949,
          "pose_affinity": 5.1
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "3",
          "pose_confidence": 0.246,
          "pose_affinity": 3.89
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "4",
          "pose_confidence": 0.33,
          "pose_affinity": 3.952
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "5",
          "pose_confidence": 0.921,
          "pose_affinity": 5.154
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "6",
          "pose_confidence": 0.358,
          "pose_affinity": 4.614
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "7",
          "pose_confidence": 0.2,
          "pose_affinity": 4.32
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "8",
          "pose_confidence": 0.614,
          "pose_affinity": 4.593
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "pose_id": "9",
          "pose_confidence": 0.922,
          "pose_affinity": 4.725
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "0",
          "pose_confidence": 0.933,
          "pose_affinity": 5.504
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "1",
          "pose_confidence": 0.944,
          "pose_affinity": 5.352
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "2",
          "pose_confidence": 0.613,
          "pose_affinity": 5.269
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "3",
          "pose_confidence": 0.401,
          "pose_affinity": 5.114
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "4",
          "pose_confidence": 0.624,
          "pose_affinity": 5.119
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "5",
          "pose_confidence": 0.883,
          "pose_affinity": 5.643
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "6",
          "pose_confidence": 0.044,
          "pose_affinity": 3.59
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "7",
          "pose_confidence": 0.026,
          "pose_affinity": 3.158
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "8",
          "pose_confidence": 0.027,
          "pose_affinity": 3.52
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "pose_id": "9",
          "pose_confidence": 0.021,
          "pose_affinity": 2.464
      }
  ],
  "inference_ligand_summary": [
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-1_0_conformer",
          "ligand_affinity": 4.888
      },
      {
          "pdb_id": "6tpd_protein_clean",
          "ligand_id": "6tpd_jak1-7_0_conformer",
          "ligand_affinity": 5.407
      }
  ]
}

export const colors = [purple,
  amber,
  deepPurple,
  green,
  brown,
  blue,
  red,
  lightBlue,
  orange,
  cyan,
  yellow,
  teal,
  deepOrange,
  lightGreen,
  blueGrey,
  lime,
  pink];
export const colorHues = [50, 100, 200, 300, 400, 500, 600, 700, 800];

export function ligandColor(poseFile) {
  if (poseFile.poseAffinity && poseFile.poseConfidence) {
    return ligandColorScores(parseFloat(poseFile.poseAffinity), parseFloat(poseFile.poseConfidence))
  } else {
    return ligandColorNoScores(poseFile.ligandIndex, poseFile.poseNumber)
  }
}

function ligandColorNoScores(ligandIndex, poseNumber) {
  return colors[ligandIndex % colors.length][colorHues[poseNumber % colorHues.length]]
}

export const poseColorMap = {
  poseAffinityLowerRanges: {
    8.5: ['#7C2529', '#AF272F', '#CB333B', 
    '#EF3340', '#F8485E', '#FB637E', '#F67599', '#FFA3B5', '#FABBCB', '#FFDCE5'],
    8: ['#A9431E', '#CF4520', '#FA4616', 
    '#FF5C39', '#FF6A39', '#FF8D6D', '#FFA388', '#FFBE9F', '#FFCFB9', '#FFE3D6'],
    7.5: ['#AF6D04', '#CC8A00', '#EAAA00', 
    '#FFB81C', '#FFC845', '#FED141', '#FDD26E', '#FBD872', '#F8E08E', '#FFF2C5'],
    7: ['#AC8400', '#C99700', '#DAAA00', 
    '#FFD100', '#FEDD00', '#FCE300', '#FBE122', '#F9E547', '#F6EB61', '#FFF8A6'],
    6.5: ['#9A9500', '#A8AD00', '#B5BD00', 
    '#C4D600', '#E1E000', '#ECE81A', '#E3E935', '#E2E868', '#EEF285', '#FCFFAC'],
    6: ['#4A7729', '#4C8C2B', '#009639', 
    '#43B02A', '#6CC24A', '#A4D65E', '#A1D884', '#80E0A7', '#A2E4BB', '#C6FFDC'],
    5.5: ['#007157', '#007A53', '#009B77', 
    '#00AB84', '#00B388', '#49C5B1', '#6ECEB2', '#8CE2D0', '#A7E6D7', '#AFF2E2'],
    5: ['#007377', '#008578', '#009CA6', 
    '#00A499', '#00B2A9', '#2DCCD3', '#64CCC9', '#71DBD4', '#9CDBD9', '#B1E4E3'],
    4.5: ['#004F71', '#004B87', '#0068A6', 
    '#0077C8', '#009CDE', '#00A9E0', '#5BC2E7', '#59CBEB', '#99D6EA',  '#9ADBEB']
  },
  poseAffinityLower : ['#001871', '#001489', '#0033A0', 
  '#0047BB', '#0072CE', '#307FE2', '#6CACE4', '#92C1E9', '#9BCBEB', '#B9D9EB']
}

function ligandColorScores(poseAffinity, poseConfidence) {
  // https://stackoverflow.com/questions/6665997/switch-statement-for-greater-than-less-than
  // shows that if-else statements are more efficient than switch statements
  const poseAffinityLowerThresholds = Object.keys(poseColorMap.poseAffinityLowerRanges).sort().reverse().map(Number)
  if (poseAffinity >= poseAffinityLowerThresholds[0]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[0]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[1] && poseAffinity < poseAffinityLowerThresholds[0]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[1]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[2] && poseAffinity < poseAffinityLowerThresholds[1]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[2]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[3] && poseAffinity < poseAffinityLowerThresholds[2]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[3]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[4] && poseAffinity < poseAffinityLowerThresholds[3]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[4]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[5] && poseAffinity < poseAffinityLowerThresholds[4]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[5]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[6] && poseAffinity < poseAffinityLowerThresholds[5]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[6]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[7] && poseAffinity < poseAffinityLowerThresholds[6]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[7]])
  } else if (poseAffinity >= poseAffinityLowerThresholds[8] && poseAffinity < poseAffinityLowerThresholds[7]) {
    return poseConfidenceColorRangeAssigner(poseConfidence, 
      poseColorMap.poseAffinityLowerRanges[poseAffinityLowerThresholds[8]])
  } else {
    return poseConfidenceColorRangeAssigner(poseConfidence, poseColorMap.poseAffinityLower)
  }
}

function poseConfidenceColorRangeAssigner(poseConfidence, colorRange) {
  if (poseConfidence >= 0.9) { return colorRange[0]} else
  if (poseConfidence >= 0.8 && poseConfidence < 0.9) { return colorRange[1]} else
  if (poseConfidence >= 0.7 && poseConfidence < 0.8) { return colorRange[2]} else
  if (poseConfidence >= 0.6 && poseConfidence < 0.7) { return colorRange[3]} else
  if (poseConfidence >= 0.5 && poseConfidence < 0.6) { return colorRange[4]} else
  if (poseConfidence >= 0.4 && poseConfidence < 0.5) { return colorRange[5]} else
  if (poseConfidence >= 0.3 && poseConfidence < 0.4) { return colorRange[6]} else
  if (poseConfidence >= 0.2 && poseConfidence < 0.3) { return colorRange[7]} else
  if (poseConfidence >= 0.1 && poseConfidence < 0.2) { return colorRange[8]} else
  {return colorRange[9]}
}

/* from https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge */
export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}


export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

// AI generated
export function csvToObjectArray(csv, columnsToConvertToNumber = []) {
  const lines = csv.split('\n');
  const headers = lines[0].split(',');
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    if (lines[i] === '') break;
    const currentLine = lines[i].split(',');

    for (let j = 0; j < headers.length; j++) {
      if (columnsToConvertToNumber.includes(headers[j])) {
        obj[headers[j]] = Number(currentLine[j]);
      } else {
        obj[headers[j]] = currentLine[j];
      }
    }

    result.push(obj);
  }

  return result;
}

export const proteinLigandPairsTypes = PropTypes.arrayOf(PropTypes.shape({
  protein: PropTypes.instanceOf(File),
  docked_ligand: PropTypes.instanceOf(File),
  download_ligand_url: PropTypes.string
}))

export const InferenceResultsObjectType = PropTypes.arrayOf(PropTypes.shape({
  pdb_id: PropTypes.string.isRequired,
  ligand_id: PropTypes.string.isRequired,
  pose_id: PropTypes.string.isRequired,
  ligand_affinity: PropTypes.number,
  pose_confidence: PropTypes.number.isRequired,
}))

export const InferenceResultsType = PropTypes.shape({
  inference_results: InferenceResultsObjectType.isRequired,
  inference_ligand_summary: PropTypes.arrayOf(
    PropTypes.shape({
      pdb_id: PropTypes.string.isRequired,
      ligand_id: PropTypes.string.isRequired,
      ligand_affinity: PropTypes.number.isRequired,
      pose_confidence: PropTypes.number,
    })
  ).isRequired,
})

export async function readFileAsync(file) {
  // AI Generated Function
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsText(file);
  })
}

export function objectToCsv (inferenceResults) {
  const keys = Object.keys(inferenceResults[0])

  const header = keys.join(",") + "\n"

  const rows = inferenceResults.map((row) => {
    const values = keys.map((key) => row[key])
    return values.join(",")
  })

  return header + rows.join("\n")
}

export function base64DataString (data) {
  return `data:text/csv;base64,${btoa(data)}`
}
