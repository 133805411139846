import { useParams, useNavigate } from "react-router-dom"
import { PageStructure } from "./PageStructure"
import ResultsSection from "../components/ResultsSection"
import { csvToObjectArray, exampleInferenceResults, loadExamplePDBSDF } from "../utils"
import { useEffect, useState } from "react"
import { api } from "../api"
import { Box, CircularProgress } from "@mui/material"

export function ResultPage () {
  const { runId } = useParams()
  const [pdb, setPdb] = useState(null)
  const [proteinLigandPairs, setProteinLigandPairs] = useState([])
  const [inferenceResults, setInferenceResults] = useState(null)
  const [reference, setReference] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      loadExamplePDBSDF().then(({examplePdb, exampleSdfs}) =>  {
        setPdb(examplePdb)
        let newPdbS3Path = `s3://foobar/${examplePdb.name}_${examplePdb.name}`
        let newSdfsState = exampleSdfs
          .map(exampleSdf => ({file: exampleSdf, s3Path: `s3://foobar/${exampleSdf.name}`}))
          .reduce((prev, curr) => { let next = {...prev}; next[curr.file.name] = curr; return next}, {})
        let pairs = Object.keys(newSdfsState).map((sdfName) => ({"protein": examplePdb, "docked_ligand": newSdfsState[sdfName].file}))
        setProteinLigandPairs(pairs)
        setInferenceResults(exampleInferenceResults)
      })
    }

    // get the pdb file based on the runId from the api
    getResultFiles()
  }, [])

  const getResultFiles= async () => {
    let resultStructure
    try {
      resultStructure = await api.getResultsStructure(runId)
    } catch (error) {
      if (error.message.includes('Invalid access token')) {
        navigate(`/login/${runId}`)
      }
      return
    }
    const pdbFile = await api.fetchFile(resultStructure.inputs.pdb, "pdb")
    setPdb(pdbFile)
    let pairs = []
    if (resultStructure.results.docking != null && resultStructure.results.docking.length > 0) {
      for (let sdf of resultStructure.results.docking) {
        let ligandFile = await api.fetchFile(sdf, "sdf")
        let ligandFileBlob = new Blob([ligandFile], {type: ligandFile.type});
        ligandFile = new File([ligandFileBlob], ligandFile.name.split("--").pop(), {type: ligandFile.type});
        pairs.push({protein: pdbFile, docked_ligand: ligandFile, download_ligand_url: sdf })
      }
    } else {
      for (let sdf of resultStructure.inputs.sdfs) {
        let ligandFile = await api.fetchFile(sdf, "sdf")
        pairs.push({protein: pdbFile, docked_ligand: ligandFile})
      }
    }
    setProteinLigandPairs(pairs)
    
    if (resultStructure.inputs.reference_ligand != null) {
      let referenceFile = await api.fetchFile(resultStructure.inputs.reference_ligand, "sdf")
      setReference(referenceFile)
    }

    let ligandAffnityPredictions = await api.fetchFile(resultStructure.results.hydrascreen.ligand_affinity_predictions, "csv")
    let posePredictions = await api.fetchFile(resultStructure.results.hydrascreen.pose_predictions, "csv")
    setInferenceResults({"inference_ligand_summary": csvToObjectArray(ligandAffnityPredictions, ["ligand_affinity"]), 
                          "inference_results": csvToObjectArray(posePredictions, ["pose_confidence", "pose_affinity"])})

  }

  return <PageStructure  title={`HydraScreen - result: ${runId}`}>
    {inferenceResults ? <ResultsSection inferenceResults={inferenceResults}
                    proteinLigandPairs={proteinLigandPairs} pdb={pdb} reference={reference} app={true} /> 
    : <Box sx={{textAlign: "center"}}><CircularProgress /></Box>}
  </PageStructure>
}
