import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useState } from "react"
import { StyledButton } from "../components/StyledButton.jsx";
import { FeedbackModal } from "../components/FeedbackModal.jsx";
import PropTypes from "prop-types";

FeedbackButton.propTypes = {
  sx: PropTypes.object,
  text: PropTypes.string,
};

FeedbackButton.defaultProps = {
  sx: {},
}

export function FeedbackButton({sx, text}) {
    const [feedbackModalIsOpen, setFeedbackModalIsOpen] = useState(false)

  return (
    <>
    { !!text ? <StyledButton onClick={() => setFeedbackModalIsOpen(true)} sx={sx}>
        {text}
      </StyledButton> : 
      <StyledButton startIcon={<ChatBubbleOutlineIcon />} 
                    onClick={() => setFeedbackModalIsOpen(true)} sx={sx}>
        Feedback
      </StyledButton> }

      <FeedbackModal feedbackModalIsOpen={feedbackModalIsOpen}
                    setFeedbackModalIsOpen={setFeedbackModalIsOpen}/>
    </>
  )
}

export default FeedbackButton;
