import { Fragment, useEffect, useState } from "react"
import { StyledFileInput } from "../StyledFileInput"
import { Auth, api } from "../../api"
import * as PropTypes from "prop-types"
import { Grid, LinearProgress, List, ListItem, ListItemIcon } from "@mui/material"
import { WarningAmber } from "@mui/icons-material"
import { Modal } from "../Modal"
import { loadExamplePDBSDF } from "../../utils"
import { InputStructure } from "../InputStructure"
import { InputStructureDescription } from '../InputStructureDescription'

UploadProteinLigands.propTypes = {
  auth: PropTypes.instanceOf(Auth).isRequired,
  beforeFileUpload: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  onFileUploaded: PropTypes.func.isRequired,
  submitPageDone: PropTypes.bool.isRequired, 
  setSubmitPageDone: PropTypes.func.isRequired
}

UploadProteinLigands.defaultProps = {
  disabled: false,
}

export function UploadProteinLigands ({ auth, beforeFileUpload, disabled, onFileUploaded, submitPageDone, setSubmitPageDone }) {
  const [pdb, setPdb] = useState(null)
  const [pdbS3Path, setPdbS3Path] = useState(null)
  const [sdfs, setSdfs] = useState({})
  const [loadingSdfs, setLoadingSdfs] = useState(false)
  const [errorModal, setErrorModal] = useState(null) // object with title and description
  const [proteinInputActivity, setProteinInputActivity] = useState("active")
  const [ligandInputActivity, setLigandInputActivity] = useState("inactive")
  const [totalPosesNumber, setTotalPosesNumber] = useState(0)

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      loadExamplePDBSDF().then(({examplePdb, exampleSdfs}) =>  {
        let newPdb = examplePdb
        setPdb(newPdb);
        let newPdbS3Path = `s3://foobar/${examplePdb.name}_${newPdb.name}`
        setPdbS3Path(newPdbS3Path)
        setProteinInputActivity("done")
        let newSdfsState = exampleSdfs
          .map(exampleSdf => ({file: exampleSdf, s3Path: `s3://foobar/${exampleSdf.name}`}))
          .reduce((prev, curr) => { let next = {...prev}; next[curr.file.name] = curr; return next}, {})
        setSdfs(newSdfsState)
        setLigandInputActivity("active")
        onFileUploaded(
          Object.keys(newSdfsState)
            .map((sdfName) => ({ "protein": newPdbS3Path, "docked_ligand": newSdfsState[sdfName].s3Path })),
          Object.keys(newSdfsState)
            .map((sdfName) => ({"protein": newPdb, "docked_ligand": newSdfsState[sdfName].file})),
          newPdb
        )
        setSubmitPageDone(true)
      })
    }
  }, [])

  useEffect(() => {
    if (submitPageDone) {
      setProteinInputActivity("done");
      setLigandInputActivity("done");
    }
  }, [submitPageDone])

  useEffect(() => {
    let totalPoses = 0;
    Object.keys(sdfs).map((sdfKey) => {
      const sdf = sdfs[sdfKey];
      if ('sdfString' in sdf) {
        const currentPoses = sdf.sdfString.split('$$$$').length - 1;
        totalPoses += currentPoses;
      }
    });
    setTotalPosesNumber(totalPoses);
  }, [sdfs])

  const sendDataToParent = (newSdfs) => {
    onFileUploaded(
    Object.keys(newSdfs)
      .map((sdfName) => ({ "protein": pdbS3Path, "docked_ligand": newSdfs[sdfName].s3Path })), 
    Object.keys(newSdfs)
      .map((sdfName) => ({"protein": pdb, "docked_ligand": newSdfs[sdfName].file, "sdf_string": newSdfs[sdfName].sdfString})),
    pdb
    )
  }

  const uploadPdbFile = async (newPdb) => {
    if (newPdb instanceof FileList) {
      newPdb = newPdb.item(0);
    }
    beforeFileUpload()
    // TODO: loading icon as sdf upload is blocked while pdb is uploading
    try {
      setPdb(newPdb)
      let responseData = await api.uploadProteinPdb(auth, newPdb)
      setPdbS3Path(responseData["s3_key"])
      setProteinInputActivity("done")
      setLigandInputActivity("active")
    } catch (e) {
      setPdb(null)
      setPdbS3Path(null)
      setErrorModal({ title: "Failed to upload file.", description: e.message })
    }
    sendDataToParent({})
  }

  const uploadSdfFile = async (file) => {
    try {
      let responseData = await api.uploadLigandFile(auth, pdbS3Path, file)
      return { file, s3Path: responseData["s3_key"], sdfString: responseData['sdf_str'] }
    } catch (e) {
      return { file, error: e.message }
    }
  }

  const handleSdfsUploaded = (responses) => {
    let newSdfsStateItems = {};
    let errors = [];
    let totalPoses = totalPosesNumber;
    responses.forEach(sdfStateItem => {
      if (sdfStateItem.error != null) {
        errors.push({ filename: sdfStateItem.file.name, reason: sdfStateItem.error });
      } else {
        const currentFilePoses = sdfStateItem.sdfString.split('$$$$').length - 1;
        totalPoses += currentFilePoses;
        if (totalPoses > 200) {
          errors.push({
            filename: sdfStateItem.file.name, 
            reason: "Total number of poses across all uploaded ligands should not exceed 200."
          });
        } else {
          newSdfsStateItems[sdfStateItem.file.name] = sdfStateItem;
          setTotalPosesNumber(totalPoses);
        }
      }
    })
    return { newSdfsStateItems, errors }
  }

  const compileSdfErrorModalData = (errors) => {
    return {
      title: `Failed to upload ${errors.length === 1 ? "file" : "some files"}`,
      description:
        <List>
          {errors.map((error, i) => <ListItem
            key={i}><ListItemIcon><WarningAmber /></ListItemIcon>{error.reason}</ListItem>)}
        </List>
    }
  }

  const getOnSdfFileChangeHandler = (sdfName) =>
    async (newSdfs) => {
      setSubmitPageDone(false);
      if (newSdfs instanceof FileList) {
        let sdfArray = [];
        for (let i=0; i < newSdfs.length; i++) {
          sdfArray.push(newSdfs.item(i))
        }
        newSdfs = sdfArray
      }

      if (ligandInputActivity === "done") {
        setLigandInputActivity("active")
      }

      if (newSdfs.length === 0 && sdfName !== "new_file") { // enable remove upload file button
        let newSdfsState = { ...sdfs }
        delete newSdfsState[sdfName]
        setSdfs(newSdfsState)
        sendDataToParent(newSdfsState)
        return
      }
      let newSdfsState = { ...sdfs }
      if (!(sdfName in newSdfs.map(file => file.name))) {
        delete newSdfsState[sdfName]
      }

      beforeFileUpload()
      setLoadingSdfs(true)

      let fileUploadPromises = newSdfs
        .filter(file => !(file.name in newSdfsState))
        .map(sdfFile => uploadSdfFile(sdfFile))

      Promise.all(fileUploadPromises)
        .then(handleSdfsUploaded)
        .then(({ newSdfsStateItems, errors }) => {
          if (errors.length > 0) {
            setErrorModal(compileSdfErrorModalData(errors))
          }
          newSdfsState = { ...newSdfsState, ...newSdfsStateItems }
          setSdfs(newSdfsState)
          sendDataToParent(newSdfsState)
        })
        .catch((e) => {
          console.error(e)
          sendDataToParent(newSdfsState)
        }).finally(() => setLoadingSdfs(false))
    }

  const onPdbFileChange = (newPdb) => {
    setSubmitPageDone(false);
    if (newPdb == null) {
      setPdb(null)
      setPdbS3Path(null)
      sendDataToParent([])
      setProteinInputActivity("active")
      setLigandInputActivity("inactive")
    } else {
      uploadPdbFile(newPdb)
    }
    // Every change assumes sdfs are stale
    setSdfs({})
    sendDataToParent([])
  }

  function isPdbUploading () {
    return pdb != null && pdbS3Path == null
  }

  return (
    <Fragment>
      <InputStructure inputNumber={1} title={"Protein PDB File"} activityState={proteinInputActivity}>
        <InputStructureDescription>
          The protein .pdb file should be in the proper protonation state and only contain amino acids. Water, ions, and other cofactors are not presently allowed.
        </InputStructureDescription>
          
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledFileInput
              value={pdb}
              supportedFiletypes={["PDB"]}
              onChange={onPdbFileChange}
              disabled={disabled || isPdbUploading()}
            />
            {isPdbUploading() && <LinearProgress />}
          </Grid>
        </Grid>

      </InputStructure>

      <InputStructure inputNumber={2} title={"Ligand SDF, mol2, or PDB files of docked poses"} activityState={ligandInputActivity} noBorder>
        <InputStructureDescription>
          One or more .sdf, .mol2, or .pdb files of ligand poses, up to 200 poses overall. Each file should contain only one chemical compound, but may contain multiple poses thereof. The poses need to include all hydrogens and be in the proper protonation state (i.e. as used for docking). Only organic compounds are allowed at present. Ligands in .pdb format should include CONECT records.
        </InputStructureDescription>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <StyledFileInput
            supportedFiletypes={["SDF", "PDB", "MOL2"]}
            value={null}
            onChange={getOnSdfFileChangeHandler("new_file")}
            multiple={true}
            disabled={disabled || pdbS3Path == null || loadingSdfs}
            sx={{ mb: 2 }}
          />
          {!!pdb && !!pdbS3Path && Object.keys(sdfs).map((sdfName, i) => {
            return (
              <StyledFileInput
                key={i}
                value={sdfs[sdfName].file}
                onChange={getOnSdfFileChangeHandler(sdfName)}
                multiple={true}
                disabled={disabled || loadingSdfs}
                sx={{ mb: 2 }}
              />
            )
          })}

          {loadingSdfs && <LinearProgress />}
        </Grid>
      </Grid>
      </InputStructure>
      {errorModal && <Modal
        onClose={() => setErrorModal(null)}
        title={errorModal.title}
        open={true}>{errorModal.description}</Modal>}
    </Fragment>
  )
}
