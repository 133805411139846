import { useState } from "react"
import { PageStructure } from "./PageStructure"
import { Modal } from "../components/Modal"
import { Container } from "@mui/material"
import { LoginInput } from "../components/LoginInput"

export function LoginPage () {
  const [modal, setModal] = useState(null) // object with title and description
  const [emailInputDisabled, setEmailInputDisabled] = useState(false)
  const [emailAcitivityState, setEmailActivityState] = useState('active')

  const onEmailSubmitSuccess = () => {
    setModal({ title: "Email Authentication.", description: "Please check your email and follow the instructions to log in." })
    setEmailInputDisabled(true)
    setEmailActivityState('done')
  }
  
  const onEmailSubmitError = (error) => {
    setModal({ title: "Failed to authenticate email.", description: error.message })
  }

  return <PageStructure  title={`HydraScreen - Log In`}>
    <Container component="main" maxWidth="md" sx={{ mb: 4, textAlign: 'center' }}>
      <LoginInput emailAcitivityState={emailAcitivityState} 
                emailInputDisabled={emailInputDisabled} 
                onEmailSubmitSuccess={onEmailSubmitSuccess}
                onEmailSubmitError={onEmailSubmitError}
                inputNumber={1}
                noBorder={true} />
    </Container>

    {modal && <Modal
    onClose={() => setModal(null)}
    title={modal.title}
    open={true}>{modal.description}</Modal>}
  </PageStructure>
}
