import { poseColorMap } from "../utils"
import { DataGrid } from "@mui/x-data-grid"
import { Box } from "@mui/material"

export function PoseColorExplanationTable({}) {

    const colorTableColumns = []
    colorTableColumns.push({
        field: "pose_confidence",
        headerName: "Pose Confidence / Affinity",
        flex: 0.8,
        headerClassName: "results-header-class"
    })
    const poseAffinityLowerThresholds = Object.keys(poseColorMap.poseAffinityLowerRanges).sort().reverse()
    // make a column for each lower threshold for each affinity segment
    for (let i in poseAffinityLowerThresholds) {
        colorTableColumns.push({
            field: `>${poseAffinityLowerThresholds[i]}`, 
            headerName: `>${poseAffinityLowerThresholds[i]}`, 
            headerClassName: "results-header-class",
            renderCell: renderCellPoseColor,
            flex: 0.2,
            sortable: false
        })
    }
    // make a column for the lowest affinity segment that has no lower bound
    colorTableColumns.push({
        field: `${poseAffinityLowerThresholds[poseAffinityLowerThresholds.length -1]}<`,
        headerName: `${poseAffinityLowerThresholds[poseAffinityLowerThresholds.length -1]}<`,
        headerClassName: "results-header-class",
        renderCell: renderCellPoseColor,
        flex: 0.2,
        sortable: false
    })

    const colorTableRows = []
    for (let i = 1; i > 0.01; i -= 0.1) {
        colorTableRows.push({
            id: i.toFixed(1),
            pose_confidence: `${i.toFixed(1)}`
        })
    }
    for (let i in poseAffinityLowerThresholds) {
        let lowerThreshold = poseAffinityLowerThresholds[i]
        for (let i = 10; i > 0; i --) {
            // populate each segmemt based on its lower bound threshold
            colorTableRows.find(row => row.id === (i/10).toFixed(1))[`>${lowerThreshold}`] = poseColorMap.poseAffinityLowerRanges[lowerThreshold][10-i]
        }
    }
    for (let i = 10; i > 0; i --) {
        // populate the unbound lowest segment
        colorTableRows.find(row => row.id === (i/10).toFixed(1))[`${poseAffinityLowerThresholds[poseAffinityLowerThresholds.length - 1]}<`] = poseColorMap.poseAffinityLower[10-i]
    }


    function renderCellPoseColor (params) {
        return <Box sx={{
                backgroundColor: params.row[params.field],
                width: 10,
                height: 10
            }} />
    }

    return  <DataGrid columns={colorTableColumns}
                rows={colorTableRows}
                pageSize={10}
                rowHeight={40}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                hideFooter
                hideFooterPagination
                hideFooterRowCount
                hideFooterSelectedRowCount  />
}