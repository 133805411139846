import { useContext, useEffect, useState } from "react"
import { StyledFileInput } from "../StyledFileInput"
import { StyledButton } from "../StyledButton"
import { api } from "../../api"
import * as PropTypes from "prop-types"
import {
  Box,
  Link,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Radio,
  RadioGroup
} from "@mui/material"
import { WarningAmber } from "@mui/icons-material"
import { Modal } from "../Modal"
import { InputStructure } from "../InputStructure"
import { InputStructureDescription } from "../InputStructureDescription"
import { AuthenticationContext } from "../../context"
import { useNavigate } from "react-router-dom"
import { LoginInput } from "../LoginInput"
import { DockingInformation } from "../DockingInformation"

UploadProteinLigands.propTypes = {
  beforeFileUpload: PropTypes.func.isRequired,
  onFileUploaded: PropTypes.func.isRequired,
}

const EMAIL_ACTIVITY_NUMBER = 1
const DOCKING_ACTIVITY_NUMBER = 2
const PROTEIN_ACTIVITY_NUMBER = 3
const LIGAND_ACTIVITY_NUMBER = 4
const REFERENCE_ACTIVITY_NUMBER = 5

export default function UploadProteinLigands ({ beforeFileUpload, onFileUploaded }) {
  const authContext = useContext(AuthenticationContext)
  const navigate = useNavigate()
  const [pdb, setPdb] = useState(null)
  const [pdbS3Path, setPdbS3Path] = useState(null)
  const [reference, setReference] = useState(null)
  const [referenceS3Path, setReferenceS3Path] = useState(null)
  const [sdfs, setSdfs] = useState({})
  const [loadingSdfs, setLoadingSdfs] = useState(false)
  const [dockingSelected, setDockingSelected] = useState(false)
  const [modal, setModal] = useState(null) // object with title and description
  const [activeInputStructureNumber, setActiveInputStructureNumber] = useState(EMAIL_ACTIVITY_NUMBER)
  const [totalPosesNumber, setTotalPosesNumber] = useState(0)
  const [inferenceLoading, setInferenceLoading] = useState(false)
  const [dockingSubmitted, setDockingSubmitted] = useState(false)
  const [referenceUploadedWithProtein, setReferenceUploadedWithProtein] = useState(false)

  const userLoggedIn = authContext.isUserLoggedIn()

  useEffect(() => {
    if (authContext.user) {
      setActiveInputStructureNumber(PROTEIN_ACTIVITY_NUMBER)
    } else {
      setActiveInputStructureNumber(EMAIL_ACTIVITY_NUMBER)
    }
  }, [authContext.user])

  useEffect(() => {
    const totalPoses = Object.values(sdfs)
      .filter(sdf => "sdfString" in sdf)
      .reduce((total, sdf) => total + (sdf.sdfString.split("$$$$").length - 1), 0)

    setTotalPosesNumber(totalPoses)
  }, [sdfs])

  const onNewSdfsUploaded = (newSdfs) => {
    const dockedLigands = Object.keys(newSdfs).map(sdfName => ({
      "protein": pdbS3Path,
      "docked_ligand": newSdfs[sdfName].s3Path
    }))
    const dockedLigandsDetails = Object.keys(newSdfs).map(sdfName => ({
      "protein": pdb,
      "docked_ligand": newSdfs[sdfName].file,
      "sdf_string": newSdfs[sdfName].sdfString
    }))

    onFileUploaded(dockedLigands, dockedLigandsDetails, pdb)
  }

  function makeProteinUploadMessageDesciption(message) {
    const messageSplit = message.split("Reference ligand upload failure with message:")
    
    return <>
      <h4>Protein upload successful with message:</h4>
      {messageSplit[0].replace("Protein upload successful with message:", "")}
      {messageSplit.length > 1 && <>
        <h4>Reference ligand upload failure with message:</h4>
        {messageSplit[1].replace("Reference ligand upload failure with message:", "")}
      </>}  
    </>
  }

  const uploadPdbFile = async (newPdb) => {
    if (newPdb instanceof FileList) {
      newPdb = newPdb.item(0)
    }
    beforeFileUpload()
    // TODO: loading icon as sdf upload is blocked while pdb is uploading
    try {
      setPdb(newPdb)
      let responseData = await api.uploadProteinWithReferencePdb(authContext.user, newPdb)
      setPdbS3Path(responseData["s3_key"])
      if ("reference_s3_key" in responseData) {
        setReferenceS3Path(responseData["reference_s3_key"])
        setReference(await getReferenceFile(responseData["reference_s3_key"]))
        setReferenceUploadedWithProtein(true)
      }
      if (responseData.message.includes("Reference ligand upload failure with message:")) {
        setModal({title: "Protein upload sucessful", description: makeProteinUploadMessageDesciption(responseData.message)})
      }
      setActiveInputStructureNumber(LIGAND_ACTIVITY_NUMBER)
    } catch (e) {
      clearPDBFileInputs()
      setReferenceS3Path(null)
      setModal({ title: "Failed to upload file.", description: e.message })
      setReferenceUploadedWithProtein(false)
    }
    onNewSdfsUploaded({})
  }

  const onPdbFileChange = (newPdb) => {
    if (newPdb == null) {
      clearPDBFileInputs()
      onNewSdfsUploaded([])
      setActiveInputStructureNumber(PROTEIN_ACTIVITY_NUMBER)
    } else {
      uploadPdbFile(newPdb)
    }
    // Every change assumes sdfs are stale
    clearLigandFileInputs()
    clearReferenceLigandFileInputs()
  }

  function isPdbUploading () {
    return pdb != null && pdbS3Path == null
  }

  const getReferenceFile = async (referenceFileKey) => {
    let responseData = await api.getFile(referenceFileKey)
    return new File([responseData], referenceFileKey.split("/").pop())
  }

  const uploadSdfFile = async (file, checkIfDocked = false) => {
    try {
      let responseData = await api.uploadLigandFile(authContext.user, pdbS3Path, file, checkIfDocked)
      return { file, s3Path: responseData["s3_key"], sdfString: responseData["sdf_str"] }
    } catch (e) {
      return { file, error: e.message }
    }
  }

  const handleSdfsUploaded = (responses) => {
    let newSdfsStateItems = {}
    let errors = []
    let totalPoses = totalPosesNumber
    responses.forEach(sdfStateItem => {
      if (sdfStateItem.error != null) {
        errors.push({ filename: sdfStateItem.file.name, reason: sdfStateItem.error })
      } else {
        const currentFilePoses = sdfStateItem.sdfString.split("$$$$").length - 1
        totalPoses += currentFilePoses
        if (totalPoses > 200) {
          errors.push({
            filename: sdfStateItem.file.name,
            reason: "Total number of poses across all uploaded ligands should not exceed 200."
          })
        } else {
          newSdfsStateItems[sdfStateItem.file.name] = sdfStateItem
          setTotalPosesNumber(totalPoses)
        }
      }
    })
    return { newSdfsStateItems, errors }
  }

  const compileSdfModalData = (errors) => {
    return {
      title: `Failed to upload ${errors.length === 1 ? "file" : "some files"}`,
      description:
        <List>
          {errors.map((error, i) => <ListItem
            key={i}><ListItemIcon><WarningAmber /></ListItemIcon>{error.reason}</ListItem>)}
        </List>
    }
  }

  const getOnSdfFileChangeHandler = (sdfName) =>
    async (newSdfs) => {
      if (newSdfs instanceof FileList) {
        newSdfs = Array.from(newSdfs)
      }

      if (activeInputStructureNumber > LIGAND_ACTIVITY_NUMBER) {
        setActiveInputStructureNumber(LIGAND_ACTIVITY_NUMBER)
      }

      if (newSdfs.length === 0 && sdfName !== "new_file") { // enable remove upload file button
        let newSdfsState = { ...sdfs }
        delete newSdfsState[sdfName]
        setSdfs(newSdfsState)
        onNewSdfsUploaded(newSdfsState)
        return
      }

      let newSdfsState = { ...sdfs }
      if (!(sdfName in newSdfs.map(file => file.name))) {
        delete newSdfsState[sdfName]
      }

      beforeFileUpload()
      setLoadingSdfs(true)

      let fileUploadPromises = newSdfs
        .filter(file => !(file.name in newSdfsState))
        .map(sdfFile => uploadSdfFile(sdfFile, !dockingSelected))

      try {
        const { newSdfsStateItems, errors } = await Promise.all(fileUploadPromises).then(handleSdfsUploaded)

        if (errors.length > 0) {
          setModal(compileSdfModalData(errors))
        }

        const finalNewSdfsState = { ...newSdfsState, ...newSdfsStateItems }
        setSdfs(finalNewSdfsState)
        onNewSdfsUploaded(finalNewSdfsState)
      } catch (error) {
        console.error(error)
        onNewSdfsUploaded(newSdfsState)
      } finally {
        setLoadingSdfs(false)
      }
    }

  function onEmailSubmitSuccess () {
    setActiveInputStructureNumber(PROTEIN_ACTIVITY_NUMBER)
    setModal({ title: "Email Authentication.", description: "Please check your email and follow the instructions to log in." })
  }
  
  function onEmailSubmitError (error) {
    setModal({ title: "Failed to authenticate email.", description: error.message })
  }

  function determineActivityState (inputNumber) {
    if (activeInputStructureNumber === inputNumber) {
      return "active"
    } else if (activeInputStructureNumber > inputNumber) {
      return "done"
    }
    return "inactive"
  }

  function clearReferenceLigandFileInputs() {
    setReference(null)
    setReferenceS3Path(null)
  }

  function clearLigandFileInputs() {
    setSdfs({})
    onNewSdfsUploaded([])
  }

  function clearPDBFileInputs() {
    setPdb(null)
    setPdbS3Path(null)
  }

  function clearAllFileInputs() {
    clearPDBFileInputs()
    clearLigandFileInputs()
    clearReferenceLigandFileInputs()
  }

  function handleReferenceLigandUpload (newRef) {
    if (newRef == null) {
      clearReferenceLigandFileInputs()
      setActiveInputStructureNumber(REFERENCE_ACTIVITY_NUMBER)
    } else {
      uploadReferenceFile(newRef)
    }
  }

  const uploadReferenceFile = async (newRef) => {
    if (newRef instanceof FileList) {
      newRef = newRef.item(0)
    }
    beforeFileUpload()

    function changeFileName(file, newName) {
      return new File([file], newName, {type: file.type, lastModified: file.lastModified});
    }

    const newName = newRef.name.split(".")[0] + "_reference_ligand." + newRef.name.split(".")[1]
    newRef = changeFileName(newRef, newName)

    try {
      setReference(newRef)
      let responseData = await api.uploadLigandFile(authContext.user, pdbS3Path, newRef)
      setReferenceS3Path(responseData["s3_key"])
      setActiveInputStructureNumber(REFERENCE_ACTIVITY_NUMBER + 1)
    } catch (e) {
      clearReferenceLigandFileInputs()
      setModal({ title: "Failed to upload reference file.", description: e.message })
    }
  }

  function isReferenceLoading () {
    return reference != null && referenceS3Path == null
  }

  function determineSubmitDisabled () {
    let filesAddedForDocking = dockingSelected && pdbS3Path && referenceS3Path && Object.keys(sdfs).length > 0 && !dockingSubmitted
    let filesAddedForNoDocking = !dockingSelected && pdbS3Path && Object.keys(sdfs).length > 0
    if (filesAddedForDocking) {
      return false
    }
    return !filesAddedForNoDocking
  }

  function getSubmitButtonTooltipTitle () {
    let title = ""
    title += !pdbS3Path ? "A protein PDB must be uploaded. " : ""
    title += Object.keys(sdfs).length === 0 ? "Ligands must be uploaded for HydraScreen. " : ""
    title += dockingSelected && !referenceS3Path ? "A reference ligand is required for docking. " : ""
    title += dockingSubmitted ? "Your run has already been submitted. " : ""
    return title.trim()
  }

  function getSelectDockingCheckboxTooltipTitle () {
    let title = ""
    title += dockingSelected && Object.keys(sdfs).length > 0 ? "Uploaded ligands have been preprocessed for docking. Remove ligands to change selection." : ""
    title += !dockingSelected && Object.keys(sdfs).length > 0 ? "Uploaded ligands have been preprocessed for HydraScreen ony run. Remove ligands to change selection." : ""
    return title.trim()
  }

  function handleSubmit () {
    if (dockingSelected) {
      runDockingInference()
    } else {
      runInference()
    }
  }

  const runDockingInference = async () => {
    setInferenceLoading(true)
    const refLigFiles = [{ "ref_file": referenceS3Path, "sdf_files": Object.keys(sdfs).map((sdfName) => sdfs[sdfName].s3Path) }]
    try {
      await api.postDockingJob(pdbS3Path, refLigFiles)
      setModal({ title: "Run submitted", description: "Your run has been submitted. You will receive an email when it is complete."})
      setDockingSubmitted(true)
    } catch (e) {
      setModal({ title: "Failed to run inference", description: e.message })
    } finally {
      setInferenceLoading(false)
    }
  }

  const runInference = async () => {
    try {
      setInferenceLoading(true)
      const proteinLigandPairs = Object.keys(sdfs).map((sdfName) => ({ "protein": pdbS3Path, "docked_ligand": sdfs[sdfName].s3Path }))
      await api.runInference(authContext.user, proteinLigandPairs)
      const pdbS3Folder = pdbS3Path.replaceAll(pdbS3Path.split("/")[-1], "").split("/protein/computed")[0]
      navigate(`/results/${btoa(pdbS3Folder)}`)
    } catch (e) {
      setModal({ title: "Failed to run inference", description: e.message })
    } finally {
      setInferenceLoading(false)
    }
  }

  const showDockingInfoModal = () => {
    setModal({title: "Docking with SMINA", width: "80%", description: <DockingInformation />})
  }

  return (
    <>
      <LoginInput emailAcitivityState={determineActivityState(EMAIL_ACTIVITY_NUMBER)}  
                  emailInputDisabled={activeInputStructureNumber > EMAIL_ACTIVITY_NUMBER} 
                  onEmailSubmitSuccess={onEmailSubmitSuccess}
                  onEmailSubmitError={onEmailSubmitError}
                  onAfterLogout={clearAllFileInputs}
                  inputNumber={EMAIL_ACTIVITY_NUMBER} /> 

      <InputStructure inputNumber={DOCKING_ACTIVITY_NUMBER}
                      title={"Perform pose generation"}
                      activityState={determineActivityState(DOCKING_ACTIVITY_NUMBER)}>
        <InputStructureDescription>
        Do you want us to generate poses through <Tooltip title="Docking is performed with SMINA, click for details">
          <Link onClick={showDockingInfoModal}  sx={{cursor: "pointer"}}>docking</Link>
          </Tooltip>?
        </InputStructureDescription>

        <FormGroup>
          <Tooltip title={getSelectDockingCheckboxTooltipTitle()}>
            <RadioGroup disabled={(Object.keys(sdfs).length > 0) || loadingSdfs}
                        size="small"    
                        defaultValue="no_docking"
                        onChange={(e) => setDockingSelected(e.target.value === "docking")}>
              <FormControlLabel value="no_docking" 
                                control={<Radio />} 
                                label="No, I have my own poses generated" 
                                disabled={!userLoggedIn || (Object.keys(sdfs).length > 0) || loadingSdfs} />
              <FormControlLabel value="docking" 
                                control={<Radio />} 
                                label="Yes, generate poses for me through docking"
                                disabled={!userLoggedIn || (Object.keys(sdfs).length > 0) || loadingSdfs} />
            </RadioGroup>
          </Tooltip>
        </FormGroup>

      </InputStructure>

      <InputStructure inputNumber={PROTEIN_ACTIVITY_NUMBER}
                      title={"Protein PDB File"}
                      activityState={determineActivityState(PROTEIN_ACTIVITY_NUMBER)}>
        <InputStructureDescription>
          The protein .pdb file should be in the proper protonation state and only contain amino acids. Water, ions, and other cofactors are not
          presently allowed.
        </InputStructureDescription>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledFileInput
              value={pdb}
              supportedFiletypes={["PDB"]}
              onChange={onPdbFileChange}
              disabled={!userLoggedIn || isPdbUploading()}
            />
            {isPdbUploading() && <LinearProgress />}
          </Grid>
        </Grid>

      </InputStructure>

      <InputStructure inputNumber={LIGAND_ACTIVITY_NUMBER}
                      title={"Ligand SDF, mol2, or PDB files " + (dockingSelected ? "to dock" : "of generated poses")}
                      activityState={determineActivityState(LIGAND_ACTIVITY_NUMBER)}
                      noBorder={!dockingSelected}>
        <InputStructureDescription>
        { dockingSelected ? "One or more .sdf, .mol2, or .pdb files of valid 3D ligand coordinates. Up to 10 ligands can be loaded for docking. Only organic compounds are allowed at present. Ligands in .pdb format should include CONECT records." :
          "One or more .sdf, .mol2, or .pdb files of ligand poses, up to 200 poses overall. Each file should contain only one chemical compound, but may contain multiple poses thereof. The poses need to include all hydrogens and be in the proper protonation state (i.e. as used for docking). Only organic compounds are allowed at present. Ligands in .pdb format should include CONECT records."}
        </InputStructureDescription>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <StyledFileInput
              supportedFiletypes={["SDF", "PDB", "MOL2"]}
              value={null}
              onChange={getOnSdfFileChangeHandler("new_file")}
              multiple={true}
              disabled={!userLoggedIn || pdbS3Path == null || loadingSdfs}
              sx={{ mb: 2 }}
            />
            {!!pdb && !!pdbS3Path && Object.keys(sdfs).map((sdfName, i) => {
              return (
                <StyledFileInput
                  key={i}
                  value={sdfs[sdfName].file}
                  onChange={getOnSdfFileChangeHandler(sdfName)}
                  multiple={true}
                  disabled={!userLoggedIn || loadingSdfs}
                  sx={{ mb: 2 }}
                />
              )
            })}

            {loadingSdfs && <LinearProgress />}
          </Grid>
        </Grid>
      </InputStructure>

      {dockingSelected &&
        <InputStructure inputNumber={REFERENCE_ACTIVITY_NUMBER}
                        title={`Reference ligand ${referenceUploadedWithProtein ? "(Optional)" : ""}`}
                        activityState={determineActivityState(LIGAND_ACTIVITY_NUMBER)}
                        noBorder>
          <InputStructureDescription>
          Provide an  .sdf, .mol2, or .pdb file of the ligand if the protein did not contain a reference ligand, or if the pocket for docking should be defined according to a different reference ligand.
          </InputStructureDescription>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledFileInput
                supportedFiletypes={["SDF", "PDB", "MOL2"]}
                value={reference}
                multiple={false}
                onChange={handleReferenceLigandUpload}
                disabled={!userLoggedIn || pdbS3Path == null || isReferenceLoading()}
                sx={{ mb: 2 }}
              />
              {isReferenceLoading() && <LinearProgress />}
            </Grid>
          </Grid>
        </InputStructure>
      }

      <Tooltip title={getSubmitButtonTooltipTitle()}>
        <Box>{/* Box needed for as tooltip doesn't work for disabled buttons */}
          <StyledButton secondaryColor={true}
                        size="small"
                        disabled={determineSubmitDisabled()}
                        onClick={handleSubmit}
                        sx={{ width: "160px", marginTop: "2.5rem" }}>
            {inferenceLoading ? <CircularProgress size="22.75px" /> : "Submit"}
          </StyledButton>
        </Box>
      </Tooltip>

      {modal && <Modal
        onClose={() => setModal(null)}
        width={modal.width || Modal.defaultProps.width}
        title={modal.title}
        open={true}>{modal.description}</Modal>}
    </>
  )
}
