import { useState } from "react"
import { MuiFileInput } from "mui-file-input"
import PropTypes from "prop-types"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InputAdornment from '@mui/material/InputAdornment';

const DEFAULT_INPUT_SX = {
    borderRadius: "4px",
    background: "white"
}

StyledFileInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    supportedFiletypes: PropTypes.arrayOf(PropTypes.string.isRequired),
    sx: PropTypes.object,
}

StyledFileInput.defaultProps = {
    label: "",
    value: null,
    onChange: () => {},
    disabled: false,
    multiple: false,
    supportedFiletypes: [],
    sx: DEFAULT_INPUT_SX,
}

const StyledFileInputPlaceholder = ({supportedFiletypes, fileIsBeingDragged}) => {
    return fileIsBeingDragged ? <span className="file-input-placeholder">Upload file</span> :
           <span className="file-input-placeholder">
              <p className="file-input-placeholder-underlined">Click to upload</p> &nbsp;or drag and drop&nbsp;
              <p className="file-input-placeholder-faded">({supportedFiletypes.join(", ")})</p>
           </span>
}

export function StyledFileInput({label, value, onChange, disabled, multiple, supportedFiletypes, sx}) {
    const [fileIsBeingDragged, setFileIsBeingDragged] = useState(false);

    const activeSx = {...DEFAULT_INPUT_SX, ...sx}

    const uploaded = !!value;
    const activeClassName = uploaded ? "file-input-upload pdb-file-upload-input file-input-upload-uploaded" : disabled ? "file-input-upload pdb-file-upload-input file-input-upload-disabled" : 'file-input-upload pdb-file-upload-input';

    const placeholder = supportedFiletypes.length > 0 ? <StyledFileInputPlaceholder supportedFiletypes={supportedFiletypes} fileIsBeingDragged={fileIsBeingDragged}/> : ""

    const handleDrag = function(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setFileIsBeingDragged(true);
        } else if (e.type === "dragleave") {
            setFileIsBeingDragged(false);
        }
    };


    const handleDrop = function(e) {
        e.preventDefault();
        e.stopPropagation();
        setFileIsBeingDragged(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // at least one file has been dropped so do something
            onChange(e.dataTransfer.files);
        }
    }

    return (
        <MuiFileInput
            label={label}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            variant={"standard"}
            fullWidth={true}
            className={activeClassName}
            sx={activeSx}
            multiple={multiple}
            onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}
            InputProps={{
                startAdornment: (<InputAdornment position="start">
                    <UploadFileIcon className="file-input-start-icon"/>
                </InputAdornment>)
            }}
        />
    )
}

export default StyledFileInput;