import { Fragment, useState, useEffect } from "react"
import { Stage, Component, StructureComponent  } from 'react-ngl';
import { ligandColor, readFileAsync, proteinLigandPairsTypes, InferenceResultsObjectType } from '../utils'
import * as PropTypes from "prop-types"

Visualization3D.propTypes = {
  proteinLigandPairs: proteinLigandPairsTypes,
  selectedPosesForVisualization: PropTypes.arrayOf(
    PropTypes.shape({
      ligand: PropTypes.string, 
      pdb_id: PropTypes.string, pose_id: 
      PropTypes.string})
    ),
  reference: PropTypes.instanceOf(File),
  inferenceResults: InferenceResultsObjectType
}

export function Visualization3D ({ proteinLigandPairs, selectedPosesForVisualization, reference, inferenceResults }) {
  const [posesFiles, setPosesFiles] = useState([])
  const [autoViewed, setAutoViewed] = useState(false)
  const [changingPoses, setChangingPoses] = useState(false)

  function performAutoView(component, autoViewed) {
    if (autoViewed === false) {
      component.autoView()
      component.stage.mouseControls.remove("drag-middle")
      setAutoViewed(true)
    }
  }

  async function splitFileContentAndSelectLigandsPoses(proteinLigandPairs, selectedPosesForVisualization) {
    return new Promise((resolve, reject) => {

      var resultFiles = [];

      let requests = proteinLigandPairs.map((plp, plp_i) => {
        return new Promise(async (resolve) => {
          const file = plp.docked_ligand;
          // get original sdf file name
          const originalName = file.name.replace(/\.[^/.]+$/, "").replace(" ", "_");
          // check if original name is in the selectedPosedForVisualization
          let found = false;
          let poseNumbers = [];
          let poseScores = [];
          // find if this file is selected and which poses
          for (let i = 0; i < selectedPosesForVisualization.length; i ++) {
              const spvName = selectedPosesForVisualization[i].ligand;
              const spvPdb = selectedPosesForVisualization[i].pdb_id;
              const poseNumber = parseInt(selectedPosesForVisualization[i].pose_id);
              if (spvName === originalName || `${spvPdb}_${spvName}` === originalName) {
                found = true;
                poseNumbers.push(poseNumber);
                //  find affinty and pose scores from the name and pdb and pose number in inferece results
                const result = inferenceResults.find((r) => r.pdb_id === spvPdb && r.ligand_id === spvName && r.pose_id === poseNumber.toString())
                if (result) {
                  poseScores.push({pose_affinity: result.pose_affinity, pose_confidence: result.pose_confidence})
                } else {
                  poseScores.push(null)
                }
              } 
          }
          if (!found) {
            resolve([]); 
            return;
          }
          // select specific poses for this file
          let content
          if (plp.sdf_string != null) {
            content = plp.sdf_string;
          } else {
            content = await readFileAsync(file)
          }
          const parts = content.split("$$$$\n");
          let blobs = parts.map((part, partIndex) => {
            if (part.length > 0 && poseNumbers.includes(partIndex)) {
              const poseIndex = poseNumbers.indexOf(partIndex);
              let poseAffinity;
              let poseConfidence;
              if (poseScores[poseIndex]) {
                poseAffinity = poseScores[poseIndex].pose_affinity;
                poseConfidence = poseScores[poseIndex].pose_confidence;
              }
              return {blob: new Blob([part], { type: "text/plain" }), 
                poseNumber: partIndex, 
                poseAffinity: poseAffinity, 
                poseConfidence: poseConfidence
              }
            }
            return;
          });
          // remove 'undefined'
          blobs = blobs.filter(function( element ) {
            return element !== undefined && element.blob !== undefined;
          });

          // Convert Blobs to Files using the Blob objects and FileSaver.js library
          const files = blobs.map((blob, index) => {
            const fileName = `${originalName}_${blob.poseNumber}.sdf`;
            const file = new File([blob.blob], fileName, { type: "text/plain" });
            return {fileObject: file, 
                    poseNumber: blob.poseNumber, 
                    ligandIndex: plp_i, 
                    poseConfidence: blob.poseConfidence, 
                    poseAffinity: blob.poseAffinity
                  }
          });
  
          resolve(files);
        })
        
      });
      Promise.all(requests).then((values) => {
        values.forEach(v => {
            resultFiles.push(...v);
        })
        resolve(resultFiles)
      });
    });
  }

  useEffect(() => {
    setChangingPoses(true)
  }, [proteinLigandPairs, selectedPosesForVisualization])

  useEffect(() => {
    if (changingPoses) {
      if (!proteinLigandPairs || proteinLigandPairs.length < 1 || selectedPosesForVisualization.length < 1) {
        setPosesFiles([]);
        setAutoViewed(false);
        setChangingPoses(false);
        return;
      }
      (async () => {
          const files = await splitFileContentAndSelectLigandsPoses(proteinLigandPairs, selectedPosesForVisualization)
          setPosesFiles(files);
          setChangingPoses(false);
      })()
    }
  }, [changingPoses])
  

  return (<Fragment>
    {proteinLigandPairs.length > 0 &&
      <Stage width="100%" height="70vh" params={{backgroundColor: "white"}}>
        <Component path={proteinLigandPairs[0].protein} reprList={[{type: 'cartoon'}]} onLoad={(c) => performAutoView(c, autoViewed)} />
        {!!reference && <StructureComponent key={0} path={reference} reprList={[{type: 'licorice', params: {color: "grey"}}]} />}
        {!changingPoses && posesFiles.map((poseFile, i) => 
        {return <StructureComponent key={i} path={poseFile.fileObject} reprList={[{
                                                        type: 'licorice', 
                                                        params: {
                                                          color: ligandColor(poseFile)
                                                        }}]
                                                      } />}
        )}
      </Stage>
      }
  </Fragment>)
}

